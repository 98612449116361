import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getCatalog } from './api'

import type { OfferListData } from './types'

export interface IWatchCatCatalogStore {
    count: number | null,
    list: OfferListData[] | null,
    networks: string[] | null,
    geo: string[] | null,
    goalsTypes: string[] | null,
    loading: boolean,
    updating: boolean,
    error: string | null,
    getCatalog: (search: URLSearchParams) => void,
}

export const useWatchCatCatalogStore = create<IWatchCatCatalogStore>((set) => ({
  count: null,
  list: null,
  loading: false,
  networks: null,
  geo: null,
  goalsTypes: null,
  updating: false,
  error: null,
  getCatalog: async (search) => {
    try {
      set({ count: null })
      set({ error: null })
      set({ list: null })
      set({ networks: null })
      set({ geo: null })
      set({ goalsTypes: null })
      set({ loading: true })

      const response = await getCatalog(search)
      if (!axios.isAxiosError(response)) {
        set({ count: response.data.count })
        set({ list: response.data.offers })
        set({ networks: response.data.networks })
        set({ geo: response.data.geo })
        set({ goalsTypes: response.data.goalsTypes })
      }
    } catch (error) {
      let errorText = t('watch-cat:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loading: false })
    }
  },
}))
