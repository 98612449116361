import styled from 'styled-components'

import {
  UTable,
  UTableHeader,
  UTableHeaderLabel,
  UTableBody,
  UTableWrapper,
  UTableRow,
  UTableCell,
  UTableValue,
  UTableAlertMessage,
} from '../../../shared/ui/table'

export const Table = styled(UTable)`
    box-shadow: 0px -1px 0px 0px #C2DDED inset;
`

export const TableHeader = styled(UTableHeader)`
    background: #E3F2F6;
    box-shadow: 0px -1px 0px 0px #C2DDED inset;
    color: #32383E;
`

export const TableHeaderLabel = styled(UTableHeaderLabel)`
    gap: 0.5rem;
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.2px;

    &:after, &:before {
        content: "";
        border-color: #d9d7d7;
        border-style: solid;
        border-radius: 1px;
        border-width: 4px;
        position: absolute;
        right: -1rem;
    }

    &:after {
        border-bottom-width: 0;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-width: 5px;
        margin-bottom: -6px;
    }

    &:before {
        border-bottom-width: 5px;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-width: 0;
        margin-top: -6px;
    }

    &.asc:after, &.desc:before {
        border-color: #a9b5b9;
        border-left-color: transparent;
        border-right-color: transparent;
    }

    &:not(.disabled):hover {
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;

        &:after, &:before {
            display: none;
        }
    }
`

export const TableRow = styled(UTableRow)`
    min-height: 47px;

    &.disabled {
        background-color: #fdf9f3;
    }
`

export const TableWrapper = styled(UTableWrapper)``

export const TableCell = styled(UTableCell)`
    margin: 12px 0 12px 16px;

    &:nth-child(1) {
        min-width: 150px;
        flex-grow: 2;
    }

    &:nth-child(2) {
        min-width: 100px;
        flex-grow: 1;
    }

    &:nth-child(3) {
        min-width: 100px;
        flex-grow: 0;
    }

    &:nth-child(4) {
        min-width: 170px;
        flex-grow: 1.5;
    }

    &:nth-child(5) {
        min-width: 100px;
        flex-grow: 2;
    }

    &:nth-child(6) {
        min-width: 100px;
        flex-grow: 0;
    }

    &:last-child {
        margin-right: 16px;
    }
`

export const TableValue = styled(UTableValue)`
    word-wrap: break-word;
`

export const TableAlertMessage = styled(UTableAlertMessage)``

export const TableBody = styled(UTableBody)`
    border-top: 1px solid rgba(39, 85, 114, 0.10);

    & ${TableRow} {
        box-shadow: 0px -1px 0px 0px #C2DDED inset;
    }

    & ${TableCell} {
        &:nth-child(2) ${TableValue} {
            text-transform: capitalize;
        }

        &:nth-child(3) ${TableValue} {
            & .is-empty {
                color: #D32B30;
                font-weight: 500;
            }
        }
    }
`
