import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { AntyDolphinLogo } from '../../../../../shared/ui/icon-assets/anty-dolphin-logo'
import { KeitaroLogo } from '../../../../../shared/ui/icon-assets/keitaro-logo'
import { FlexLogo } from '../../../../../shared/ui/icon-assets/flex-logo'
import { IntegrationType } from '../../../../../entities/settings/integrations-new'
import { ConfigureIntegrationButton } from '../../../../../features/user/settings/configure-integration'
import { checkCredentials } from '../lib'

import type { IntegrationsList } from '../../../../../entities/settings/integrations-new'

export type IntegrationRowProps = {
    type: IntegrationType,
    integration?: IntegrationsList,
}

export const IntegrationRow = ({ integration, type }: IntegrationRowProps) => {
  const hasIntegration = Boolean(integration)
  const hasInfo = Boolean(integration?.info)
  const isActive = Boolean(integration?.active)
  const integrationType = integration?.type || type
  const hasErrorCredentials = hasIntegration && checkCredentials(type, integration)

  return (
    <SIntegrationRow>
      <Logo>
        {integrationType === IntegrationType.ANTY_DOLPHIN && <AntyDolphinLogo />}
        {integrationType === IntegrationType.KEITARO && <KeitaroLogo />}
        {integrationType === IntegrationType.FLEX_CARDS && <FlexLogo />}
      </Logo>
      <Text>
        <Title>
          {hasIntegration
            ? integration?.title
            : (
              <>
                {integrationType === IntegrationType.ANTY_DOLPHIN && t('settings:My Anty Dolphin')}
                {integrationType === IntegrationType.KEITARO && t('settings:My Keitaro')}
                {integrationType === IntegrationType.FLEX_CARDS && t('settings:My Flex Card')}
              </>
            )}
        </Title>
        <Description>
          {hasIntegration
            ? integration?.description
            : (
              <>
                {integrationType === IntegrationType.ANTY_DOLPHIN
                    && t('settings:Anti-detect browser for affiliate marketing purposes')}
                {integrationType === IntegrationType.KEITARO
                    && t('settings:Keitaro tracker designed for media buyers and publishers')}
                {integrationType === IntegrationType.FLEX_CARDS
                    && t('settings:FlexCard has no hidden fees, and this is one of our main advantages! 🔥')}
              </>
            )}
        </Description>
        <Info>
          {!hasIntegration && (
            <LabelError>{t('settings:Not configured')}</LabelError>
          )}
          {hasIntegration && hasErrorCredentials && (
            <LabelError>{t('settings:Credentials are not configured')}</LabelError>
          )}
          {hasIntegration && !isActive && (
            <LabelError>{t('settings:Inactive')}</LabelError>
          )}
          {isActive && (
            <LabelActive>{t('settings:Active')}</LabelActive>
          )}
          {hasInfo && (
            integration?.info && Object?.entries(integration.info)?.map(([key, value]) => (
              type === IntegrationType.KEITARO && (
                key === 'isAdmin' && (
                  <LabelInfo key={key}> {/* Use a unique key here */}
                    {value ? t('settings:Administrator') : t('settings:Member')}
                  </LabelInfo>
                )
              )
            ))
          )}
        </Info>
      </Text>
      <Settings>
        <ConfigureIntegrationButton integration={integration} type={type} />
      </Settings>
    </SIntegrationRow>
  )
}

const SIntegrationRow = styled.div`
    align-content: stretch;
    display: flex;
    border-bottom: 1px solid #f3f2f2;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    padding: 10px 0;
`

const Logo = styled.div`
    border-radius: 4px;
    flex-grow: 0;

    & svg {
        border: 1px solid #f3f2f2;
        border-radius: 4px;
    }
`

const Text = styled.div`
    align-content: center;
    flex-grow: 1;
    width: 10rem;
    
    & > div { 
        overflow-wrap: anywhere;
    }
`

const Title = styled.div`
    font-size: 16px;
    font-style: normal;
`

const Description = styled.div`
    color: #8c8b8b;
    font-size: 14px;
    font-style: normal;
`

const Info = styled.div`
    display: flex;
    margin-top: 4px;
    gap: 8px;
`

const Label = styled.div`
    background-color: #f3f2f2;
    color: #8c8b8b;
    border-radius: 4px;
    font-size: 12px;
    padding: 2px 8px;
`

const LabelError = styled(Label)`
    background-color: #fbd9d9;
    color: #ff0000;
`

const LabelActive = styled(Label)`
    background-color: #D7FBD7;
    color: #009a00;
`

// const LabelWarning = styled(Label)`
//     background-color: #fff7d4;
//     color: #8f7712;
// `

const LabelInfo = styled(Label)`
    background-color: #cefdff;
    color: #00527e;
`

const Settings = styled.div`
    align-content: center;
    color: #8c8b8b;
    flex-grow: 0;
`
