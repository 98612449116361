import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { IntegrationType, useIntegrationsStore } from '../../../../../entities/settings/integrations-new'

import { IntegrationRow } from './integration-row'

export const IntegrationsBlock = () => {
  const { list, loaded } = useIntegrationsStore()

  const renderIntegrationGroup = (type: IntegrationType, title: string) => (
    <IntegrationsGroup key={type}>
      <Title>{t(title)}</Title>
      {list && list.some(integration => integration.type === type)
        ? list
          .filter(integration => integration.type === type)
          .map(integration => (
            <IntegrationRow
              key={integration.id}
              type={type}
              integration={integration}
            />
          ))
        : <IntegrationRow type={type} />
      }
    </IntegrationsGroup>
  )

  return loaded && (
    <SIntegrationsBlock>
      {renderIntegrationGroup(IntegrationType.ANTY_DOLPHIN, 'settings:Anty Dolphin')}
      {renderIntegrationGroup(IntegrationType.KEITARO, 'settings:Keitaro')}
      {renderIntegrationGroup(IntegrationType.FLEX_CARDS, 'settings:Flex Cards')}
    </SIntegrationsBlock>
  )
}

const SIntegrationsBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

const IntegrationsGroup = styled.div`
    display: flex;
    flex-direction: column;

    & > :nth-child(2) {
        border-top: 1px solid #f3f2f2;
    }
`

const Title = styled.div`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 1px;
    margin-bottom: 16px;
    text-transform: uppercase;
`
