import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { FormControlLabel, Switch, TextField } from '@mui/material'
import { darken } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { Button } from '../../../../../shared/ui/button'
import { Tooltip } from '../../../../../shared/ui/tooltip'
import {
  useCustomReportsCatalogStore,
  addGroup,
  deleteGroup,
  updateGroup,
} from '../../../../../entities/custom-reports/catalog'
import { handleAttributeChange } from '../lib'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'

import { Table, TableBody, TableCell, TableRow, TableWrapper } from './table'

import type { CustomReportsGroups } from '../../../../../entities/custom-reports/catalog'
import type { CustomReportsEditButtonProps } from '../types'


export const ManageGroupsModal = ({ onClose, isOpen }: CustomReportsEditButtonProps) => {
  const { groups, getGroups, getCatalog } = useCustomReportsCatalogStore()
  const { enqueueSnackbar } = useSnackbar()
  const [search] = useSearchParams()
  const [addGroupValue, setAddGroupValue] = useState<CustomReportsGroups['name']>()
  const [changedGroups, setChangedGroups] = useState<CustomReportsGroups[] | null>(null)

  const handleNameChange = async (id: CustomReportsGroups['id'], newName: CustomReportsGroups['name']) => {
    const updated = await handleAttributeChange(id, 'name', newName, changedGroups)
    updated && setChangedGroups(updated)
  }

  const handleDisabledChange = async (id: CustomReportsGroups['id'], newDisabled: CustomReportsGroups['disabled']) => {
    const updated = await handleAttributeChange(id, 'disabled', newDisabled, changedGroups)
    updated && setChangedGroups(updated)
  }

  const isButtonEnabled = (
    group: CustomReportsGroups,
    changedGroups: CustomReportsGroups[] | null,
    groups: CustomReportsGroups[] | null,
  ) => {
    if (!groups || !changedGroups) {
      return false // If groups or changedGroups is null, button should be disabled
    }

    const nameChanged = changedGroups.find(value => value.id === group.id)?.name
    const disabledChanged = changedGroups.find(value => value.id === group.id)?.disabled

    return !(
      (nameChanged !== group.name) || (disabledChanged !== group.disabled)
    )
  }

  const handleEditSubmit = async (group: CustomReportsGroups) => {
    if (changedGroups && changedGroups.length) {
      const editedGroup = changedGroups.find(item => item?.id === group?.id)

      if (editedGroup) {
        try {
          await updateGroup(
            {
              id: editedGroup.id,
              name: String(editedGroup.name),
              disabled: editedGroup.disabled,
            },
          )

          await getGroups()
          await getCatalog(search)

          enqueueSnackbar(
            t('custom-reports:{{name}} was updated successfully!', { name: editedGroup.name }),
            { variant: 'success' },
          )
        } catch (error: any) {
          enqueueSnackbar(
            t(`custom-reports:${error?.response?.data?.message ?? error?.message}`),
            { variant: 'error' },
          )
        }
      }
    }
  }

  const handleDeleteSubmit = async (group: CustomReportsGroups) => {
    const params = new URLSearchParams()
    params.append('id', String(group?.id))

    try {
      await deleteGroup(params)
      await getGroups()
      await getCatalog(search)

      enqueueSnackbar(
        t('custom-reports:{{name}} was deleted successfully!', { name: group.name }),
        { variant: 'success' },
      )
    } catch (error: any) {
      enqueueSnackbar(
        t(`custom-reports:${error?.response?.data?.message ?? error?.message}`),
        { variant: 'error' },
      )
    }
  }

  const handleAddSubmit = async () => {
    if (addGroupValue) {
      try {
        await addGroup(
          {
            name: addGroupValue,
          },
        )

        await getGroups()
        setAddGroupValue('')

        enqueueSnackbar(
          t('custom-reports:{{name}} was added successfully!', { name: addGroupValue }),
          { variant: 'success' },
        )
      } catch (error: any) {
        enqueueSnackbar(
          t(`custom-reports:${error?.response?.data?.message ?? error?.message}`),
          { variant: 'error' },
        )
      }
    }
  }

  // add new group to changedGroups if new id was found
  useEffect(() => {
    if (groups && changedGroups) {
      setChangedGroups(prevChangedGroups =>
        prevChangedGroups && prevChangedGroups.concat(
          groups.filter(group => !prevChangedGroups.some(prevGroup => prevGroup.id === group?.id)),
        ),
      )
    }
    if (!changedGroups) setChangedGroups(groups)
  }, [groups])

  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {t('custom-reports:Manage groups')}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>
        <SModalBody>
          <SModalForm>

            <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px' }}>
              <TextField
                sx={{
                  height: '40px',
                  '.MuiInputBase-root': { height: '40px', alignItems: 'center' },
                  '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                  '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                  '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                }}
                size={'small'}
                style={{ minWidth: '150px', flexGrow: 1 }}
                value={addGroupValue?.trimStart() ?? ''}
                label={t('custom-reports:New group name')}
                onChange={(e) => setAddGroupValue(e.target.value)}
              />
              <Button
                size={'medium'}
                variant="contained"
                color="primary"
                disabled={(!addGroupValue || addGroupValue.trimStart() === '')}
                onClick={handleAddSubmit}
              >
                {t('custom-reports:Add')}
              </Button>
            </div>

            <Table>
              <TableWrapper className={'overflow-auto'}>
                <TableBody>
                  {groups && groups?.map((group, index) => (
                    <TableRow
                      key={index}
                      className={
                        changedGroups?.find(value => value.id === group.id)?.disabled ? 'disabled' : ''
                      }>
                      <TableCell>
                        <SFormControlLabel
                          value={changedGroups?.find(value => value.id === group.id)?.disabled ?? false}
                          control={
                            <Tooltip
                              title={
                                t(changedGroups
                                  ?.find(value => value.id === group.id)?.disabled
                                  ? t('custom-reports:Disabled')
                                  : t('custom-reports:Enabled'),
                                )
                              }
                            >
                              <Switch
                                color={'primary'}
                                checked={
                                  changedGroups?.find(value => value.id === group.id)?.disabled ?? false
                                }/>
                            </Tooltip>
                          }
                          label={''}
                          onChange={() => handleDisabledChange(
                            group.id,
                            !changedGroups?.find(value => value.id === group.id)?.disabled,
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          size={'small'}
                          value={
                            changedGroups?.find(value => value.id === group.id)?.name?.trimStart() ?? ''
                          }
                          onChange={(e) => handleNameChange(group.id, e.currentTarget.value)}
                        />
                      </TableCell>

                      <TableCell>
                        <SUpdateButton
                          size={'small'}
                          variant="contained"
                          color="primary"
                          onClick={() => handleEditSubmit(group)}
                          disabled={isButtonEnabled(group, changedGroups, groups)}
                        >{t('custom-reports:Update')}
                        </SUpdateButton>
                      </TableCell>

                      <TableCell>
                        <Tooltip title={t('custom-reports:Delete')}>
                          <SDeleteButton
                            size={'small'}
                            variant="contained"
                            color="primary"
                            onClick={() => handleDeleteSubmit(group)}
                          ><DeleteOutlinedIcon/>
                          </SDeleteButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableWrapper>
            </Table>

            <div
              style={
                {
                  display: 'flex',
                  marginLeft: 'auto',
                  marginTop: 'auto',
                  gap: '15px',
                }
              }
            >
              <SModalCancel
                variant={'outlined'}
                onClick={onClose}
                size={'medium'}
                type={'button'}
                style={{ marginLeft: 'auto' }}
              >
                {t('custom-reports:Close')}
              </SModalCancel>
            </div>
          </SModalForm>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
    display: inline-flex;
    padding: 16px 24px;
    height: 100%;
    width: 500px;
`
const SModalHeader = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.primary.main};
    display: inline-flex;
    padding: 16px 24px;
    flex-shrink: 0;
    justify-content: space-between;
`
const SModalHeaderText = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha.white[100]};

    &.MuiSvgIcon-root {
        font-size: 1rem;
    }
`
const SModalForm = styled.form`
    display: flex;
    flex-direction: column;
    min-height: 280px;
    height: 100%;
    width: 100%;
`
const SModalCancel = styled(Button)`
    &.MuiButtonBase-root {
        border-color: #FF6961;
        color: #FF6961;

        &:hover {
            border-color: ${darken('#FF6961', 0.05)};
            color: ${darken('#FF6961', 0.05)};
        }
    }
`
const SDeleteButton = styled(Button)`
    &.MuiButtonBase-root {
        background: #FFE2E2;
        color: #F6453B;
        padding: 6px;
        width: 40px;

        &:hover {
            background-color: #F6453B;
            color: #FFFFFF;
        }

        & svg {
            font-size: 18px;
        }
    }
`

const SUpdateButton = styled(Button)`
    &.Mui-disabled {
        background-color: #E8EEF3;
        color: #78909C;
    }

    &.MuiSvgIcon-root {
        -webkit-transition: unset;
        transition: unset;
    }
`

const SFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root {
        margin-right: 0;
    }
`
