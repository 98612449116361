import { KaccCatalogSortEnum, useKaccCatalogStore } from '../../../entities/kacc/catalog'
import { SortOrder } from '../../../shared/configs/enums/pagination'

import type { Direction, NumberSize } from '../../../shared/ui/resizable'
import type { SortProps } from '../../../shared/lib'
import type {
  KaccCatalogAnty,
  KaccCatalogCampaigns,
  KaccCatalogFacebook,
  KaccCatalogOffer,
  KaccCatalogSettings,
  KaccCatalogSettingsAnty,
  KaccCatalogSettingsCampaigns,
  KaccCatalogSettingsFacebook,
  KaccCatalogSettingsOffers,
  KaccCatalogSettingsFilter,
  KaccCatalogStoreProps,
  KaccCatalogSettingsFormat,
} from '../../../entities/kacc/catalog'

export const resizeColumnWidth = <T extends KaccCatalogSettingsAnty
    | KaccCatalogSettingsFacebook
    | KaccCatalogSettingsCampaigns
    | KaccCatalogSettingsOffers>
  (array: T[],
    key: KaccCatalogSettingsAnty['key']
     | KaccCatalogSettingsFacebook['key']
     | KaccCatalogSettingsCampaigns['key']
     | KaccCatalogSettingsOffers['key'],
    width: KaccCatalogSettings['width'],
  ): T[] => {
  return array?.map((values) =>
    values?.key === key
      ? { ...values, width: width }
      : { ...values },
  )
}

export const handleValueEmptyData = (
  value: string | number | null | undefined,
  format: KaccCatalogSettingsFormat['format']) => {
  if (value === null || value === undefined) {
    if (format !== 'number' && format !== 'string') return setNumberFormat(0, format)

    return ''
  }

  if (typeof value === 'number') return setNumberFormat(value, format)
  if (typeof parseFloat(value) === 'number' && format !== 'string') return setNumberFormat(parseFloat(value), format)

  return value
}

const setNumberFormat = (
  value: number,
  format: KaccCatalogSettingsFormat['format'],
): number | string => {
  const locale = 'en-US'
  const currency = 'USD'

  if (format === 'percent')
    return new Intl.NumberFormat(locale, {
      style: 'percent',
      minimumFractionDigits: 2,
    }).format(value / 100)

  if (format === 'currency') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value)
  }

  if (format === 'time') {
    const days = Math.floor(value / (3600 * 24))
    value %= 3600 * 24
    const hours = Math.floor(value / 3600)
    value %= 3600
    const minutes = Math.floor(value / 60)
    value %= 60

    if (value === 0) return '0s.'

    const parts = []
    if (days) parts.push(days + 'd.')
    if (hours) parts.push(hours + 'h.')
    if (minutes || (days && hours)) parts.push(minutes + 'm.')
    if (value || (days && hours) || (days && minutes) || (hours && minutes)) parts.push(value + 's.')

    return parts.join(' ')
  }

  return value
}

export const setPriceColor = (value: number | null | undefined) => {
  if (value && value > 0) {
    return { color: 'green' }
  }

  if (value && value < 0) {
    return { color: 'red' }
  }
}

export const getSortDirectionClassName = (
  column: keyof KaccCatalogAnty
        | keyof KaccCatalogFacebook
        | keyof KaccCatalogCampaigns
        | keyof KaccCatalogOffer
        | KaccCatalogSortEnum
        | null,
  sortColumn: SortProps['sortColumn'],
  sortActive: SortProps['sortActive'],
) => {
  let direction = ''
  const initialSort: SortOrder = SortOrder.DESC
  const initialColumn: KaccCatalogSortEnum = KaccCatalogSortEnum.anty_id

  if (column === sortColumn) {
    if (sortActive === initialSort) {
      direction = initialSort
    } else {
      direction = initialSort !== SortOrder.DESC ? SortOrder.DESC : SortOrder.ASC
    }
  }

  if (!sortActive || !sortColumn) {
    direction = column === initialColumn ? initialSort : direction
  }

  return direction
}

export const handleSortChange = (
  column: keyof KaccCatalogAnty
        | keyof KaccCatalogFacebook
        | keyof KaccCatalogCampaigns
        | keyof KaccCatalogOffer
        | KaccCatalogSortEnum,
  sortActive: SortProps['sortActive'],
  sortColumn: SortProps['sortColumn'],
  setAsc: SortProps['setAsc'],
  setDesc: SortProps['setDesc'],
  toggleSort: SortProps['toggleSort'],
) => {
  const initialSort: SortOrder = SortOrder.DESC
  const initialColumn: KaccCatalogSortEnum = KaccCatalogSortEnum.anty_id

  if (!sortActive && !sortColumn) {
    column !== initialColumn ? setDesc(column) : setAsc(column)
  } else if (sortColumn && sortColumn !== column) {
    initialSort === SortOrder.DESC ? setDesc(column) : setAsc(column)
  } else {
    toggleSort(column)
  }
}

export const handleOnResize = (
  settings: KaccCatalogStoreProps['settings'],
  _event: (MouseEvent | TouchEvent),
  _direction: Direction,
  elementRef: HTMLElement,
  _delta: NumberSize,
  group: keyof KaccCatalogSettingsFilter,
  key: KaccCatalogSettingsAnty['key']
        | KaccCatalogSettingsFacebook['key']
        | KaccCatalogSettingsCampaigns['key']
        | KaccCatalogSettingsOffers['key'],
) => {
  if (settings) {
    let { anty, facebook, campaigns, offers } = settings

    if (group === 'anty') anty = resizeColumnWidth(anty, key, elementRef.offsetWidth)
    if (group === 'facebook') facebook = resizeColumnWidth(facebook, key, elementRef.offsetWidth)
    if (group === 'campaigns') campaigns = resizeColumnWidth(campaigns, key, elementRef.offsetWidth)
    if (group === 'offers') offers = resizeColumnWidth(offers, key, elementRef.offsetWidth)

    const newSettings = {
      anty: anty,
      facebook: facebook,
      campaigns: campaigns,
      offers: offers,
    }

    useKaccCatalogStore.getState().updateSettings(newSettings)
    useKaccCatalogStore.getState().saveSettings(newSettings)
  }
}
