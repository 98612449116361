import styled from 'styled-components'

import { Pagination } from '../../../shared/ui/pagination'
import { useDomainsWatcherStore } from '../../../entities/domains-watcher'
import { PageLimit } from '../../../shared/configs/enums/pagination'

export function PaginationBlock() {
  const { listCount } = useDomainsWatcherStore()

  return (
    <SPaginationBlock>
      <Pagination
        count={listCount ?? 0}
        limits={Object.values(PageLimit).map(s => Number(s))}/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
    display: flex;
    gap: 20px;
`
