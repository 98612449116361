import React from 'react'
import { t } from 'i18next'

import { DashboardIntervalFilter } from '../../../features/dashboard/filter-interval'
import { SInfoBlock, SRightBlock, STitleChart } from '../../../pages/dashboard/style'
import { DashboardCampaignFilter } from '../../../features/dashboard/filter-by-campaign'

export const GroupsCampaignFilters = () => {
  return (
    <SInfoBlock>
      <STitleChart>{t('Group campaigns')}</STitleChart>
      <SRightBlock>
        <DashboardIntervalFilter/>
        <DashboardCampaignFilter/>
        {/*<SButtonChart>{t('View Details')}</SButtonChart>*/}
      </SRightBlock>
    </SInfoBlock>
  )
}
