import React from 'react'
import { Card, CardContent, Typography, Box, Divider } from '@mui/material'
import { styled } from '@mui/system'
import { t } from 'i18next'

import { WhoisDomainCardProps } from '../types'
import { getFormattedDateStamp } from '../../../../shared/lib'

export const WhoisDomainCard = ({ whois }: WhoisDomainCardProps) => {
// Explicitly define the return type as React.ReactNode[]
  const renderKeyValuePairs = (obj: any, parentKey = ''): React.ReactNode[] => {
    return Object.entries(obj || {}).map(([key, value]) => {
      const dateKeys = [
        'domain_created_date',
        'domain_updated_date',
        'domain_expiration_date',
      ]
      const fullKey = parentKey ? `${parentKey}.${key}` : key
      const formattedKey = fullKey.replace(/\./g, '_') // Replace all dots with underscores

      if (dateKeys.includes(formattedKey)) {
        value = getFormattedDateStamp(value as string)
      }

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        // If the value is an object, recurse and render nested key-value pairs
        return renderKeyValuePairs(value, fullKey)
      } else {
        return value ? (
          <KeyValueWrapper key={fullKey}>
            <strong>{t(`domains-watcher:${formattedKey}`)}{': '}</strong> {`${value}`}
          </KeyValueWrapper>
        ) : null
      }
    })
  }

  return (
    <Box p={2}>
      {/* Whois Information */}
      {whois && (
        <>
          <CategoryCard>
            <CardContent>
              <SectionTitle>{t('domains-watcher:Whois Information')}{' ('}{whois.domain.domain}{')'}</SectionTitle>
              <DividerStyled/>
              {renderKeyValuePairs(whois)}
            </CardContent>
          </CategoryCard>
        </>
      )}
    </Box>
  )
}

// Styled components
const CategoryCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1), // Reduced padding for tighter fit
  wordWrap: 'break-word',
  width: '100%', // Ensure it fits well in a popup
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 600, // Slightly bolder for emphasis
  fontSize: '1rem',
  color: theme.palette.text.primary,
}))

const KeyValueWrapper = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: '0.875rem',
  color: theme.palette.text.secondary, // Softer color for less visual noise
  lineHeight: 1.5,
  '& strong': {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}))

const DividerStyled = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.divider, // Soft divider for clean look
}))
