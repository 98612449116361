import React, { useEffect } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Box, TextField } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { useFilter } from '../../../../shared/lib'
import { AntyListStatuses } from '../../../../entities/kacc/integration/types'

export const KeitaroStatusFilterParam = 'status'

/**
 * Table Options - Period select feature
 * @constructor {JSX.Element}
 */
export function FilterKeitaroStatus() {
  const { filterActive } = useFilter(KeitaroStatusFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const filters = Object.values(AntyListStatuses)

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) search.set(KeitaroStatusFilterParam, value as string)
    if (!value && filterActive) search.delete(KeitaroStatusFilterParam)
    setSearch(search, { replace: true })
  }

  // Check period query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive
        && (filters.indexOf(filterActive as AntyListStatuses) === -1)
      )) {
        search.delete(KeitaroStatusFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive ? t(`kacc:${filterActive}`) : null}
      // defaultValue={filterActive ? filterActive : null}
      onChange={handleOnChange}
      options={filters ?? []}
      openOnFocus={true}
      getOptionLabel={(option) => t(option)}
      noOptionsText={t('kacc:No options')}
      isOptionEqualToValue={(option, value) => t(`kacc:${option}`) === value}
      renderOption={(props, option) => (
        (option !== '' && <Box
          component="li"
          value={option}
          {...props}
          key={option}
        >
          {t(`kacc:${option}`)}
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('kacc:Keitaro status')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
