export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export enum PageLimit {
    Ten = '10',
    TwentyFive = '25',
    Fifty = '50',
    OneHundred = '100',
    TwoHundredFifty = '250',
    FiveHundreds = '500',
    Thousand = '1000',
}
