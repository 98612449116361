import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useFilter } from '../../../shared/lib'
import { DomainsWatcherSources } from '../../../entities/domains-watcher'

// SourceFilterParam query name
export const SourceFilterParam = 'source'

/**
 * SourceFilter feature
 */
export function SourceFilter() {
  const { filterActive } = useFilter(SourceFilterParam, '')
  const { offsetActive } = usePagination()
  const [search, setSearch] = useSearchParams()
  const filters = Object.keys(DomainsWatcherSources)

  /**
     * Handle onChange action
     */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(SourceFilterParam, value as string)
    if (!value && filterActive) search.delete(SourceFilterParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check source query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && !Object.values(DomainsWatcherSources).includes(filterActive as DomainsWatcherSources)
      )) {
        search.delete(SourceFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive ? t(`domains-watcher:${filterActive.toLowerCase()}`) : null}
      defaultValue={filterActive ? t(`domains-watcher:${filterActive.toLowerCase()}`) : null}
      onChange={handleOnChange}
      options={filters ?? []}
      openOnFocus={true}
      noOptionsText={t('domains-watcher:No options')}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => t(`domains-watcher:${option}.toLowerCase()`) === value}
      renderOption={(props, option) => (
        (option !== '' && <Box
          component="li"
          value={option}
          {...props}
        >
          {t(`domains-watcher:${option.toLowerCase()}`)}
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('domains-watcher:Sources')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
