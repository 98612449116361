import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'

export function CountriesData() {
  const { countries } = useDashboardStore()

  return (
    <>
      <SHeaderRow>
        <SCountryColumn>{t('Country')}</SCountryColumn>
        <SCountryColumn>{t('Revenue')}</SCountryColumn>
        <SCountryColumn>{t('Cost')}</SCountryColumn>
        <SCountryColumn>{t('Profit')}</SCountryColumn>
      </SHeaderRow>
      {countries?.map((country, index) => (
        <SCountryItem key={index}>
          <SCountryColumn>{country.country}</SCountryColumn>
          <SCountryColumn $isCurrency>{country.saleRevenue}</SCountryColumn>
          <SCountryColumn $isCurrency>{country.cost}</SCountryColumn>
          <SCountryColumn $isCurrency>{country.profitConfirmed}</SCountryColumn>
        </SCountryItem>
      ))}
    </>
  )
}

const SHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  /* text-transform: uppercase; */
  font-size: 12px;
  color: #878A99;
`

const SCountryItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;
  font-size: 12px;
`

const SCountryColumn = styled.div<{ $isCurrency?: boolean }>`
  flex: 1;
  ${({ $isCurrency }) => $isCurrency && `
    &::after {
      content: ' $';
    }
  `}
`
