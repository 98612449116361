import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Alert, CircularProgress } from '@mui/material'
import { t } from 'i18next'
// import { useTheme } from '@mui/material/styles'

import { OptionsCount } from '../../../../features/white-generator/generator/set-count'
import { Button } from '../../../../shared/ui/button'
import { useWhiteGeneratorStore } from '../../../../entities/white-generator/generator'
import { GeneratorFormValues } from '../types'

import { TableTemplates } from './table-templates'

export const GeneratorForm = () => {
  // const theme = useTheme()
  const [loading, setLoading] = useState(false)
  const {
    templates,
    generateMain,
    getGeneratedData,
    getGeneratorData,
  } = useWhiteGeneratorStore()
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object().shape({
    count: Yup.number()
      .transform((value) => {
        const parsed = parseInt(value)
        return isNaN(parsed) || parsed === 0 ? 10 : parsed
      })
      .required(t('white-generator:The count field is required')),
  })

  const formik = useFormik<GeneratorFormValues>({
    initialValues: {
      count: null,
      template: null,
      submit: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers) => {
      const { count, template } = values
      const sendValues = {
        ...(count && { count: parseInt(count) }),
        ...(template && { template_name: template.title }),
        ...(template && { group: template.group }),
      }

      try {
        setLoading(true)
        await generateMain(sendValues)

        enqueueSnackbar(t('white-generator:Templates were generated successfully!'), { variant: 'success' })

        await getGeneratedData()
        await getGeneratorData()

      } catch (error) {
        enqueueSnackbar(`${
          t('white-generator:Something went wrong!')
        } ${`white-generator:${error}`}`, { variant: 'error' })
      } finally {
        setLoading(false)
        formikHelpers.setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    formik.setFieldValue('count', templates?.count ? String(templates?.count) : null)
  }, [templates?.count])

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <TableTemplates formik={formik}/>
      <Options>
        <OptionsCount
          value={formik.values.count}
          handleOnChange={(value: string | null) => formik.setFieldValue('count', value)}
          error={Boolean(formik.touched.count && formik.errors.count)}
          inputWidth={'250px'}
        />
      </Options>

      {Boolean(
        formik.touched.submit
        && Object.keys(formik.errors).length > 0,
      ) && (
        <div
          style={{
            marginBottom: '1rem',
            marginTop: '0.5rem',
          }}
        >
          <Alert
            severity="error"
            style={{
              width: 'fit-content',
            }}
          >
            {formik.errors.count && <div>
              {t(`white-generator:${formik.errors.count}`)} </div>
            }
            {formik.errors.template && <div>
              {t(`white-generator:${formik.errors.template}`)} </div>
            }
          </Alert>
        </div>
      )}

      <Button
        startIcon={loading
          ? <CircularProgress size="1rem"/>
          : null}
        disabled={loading
          || formik.values.template === null}
        type={'submit'}
        sx={(theme) => theme.customButtons.textSizeExtraLargeButton}
        variant={'contained'}
      >
        {loading
          ? t('white-generator:Generating')
          : t('white-generator:Generate')}
      </Button>

    </form>
  )
}

const Options = styled.div`
  border-bottom: 1px dotted #B7C6D3;
  display: flex;
  gap: 16px;
  padding: 16px 0;
`
