import type {
  KaccCatalogAnty,
  KaccCatalogCampaigns,
  KaccCatalogFacebook,
  KaccCatalogOffer,
  KaccCatalogSettingsDefault,
  KaccCatalogSettingsFilter,
  KaccCatalogSettingsFormat,
  KaccCatalogSettingsGroup,
} from './types'

const SETTINGS_ANTY_KEYS: KaccCatalogSettingsDefault<keyof KaccCatalogAnty>[] = [
  { key: 'anty_id', width: 150 },
  { key: 'anty', width: 300 },
]

const SETTINGS_FACEBOOK_KEYS: KaccCatalogSettingsDefault<keyof KaccCatalogFacebook>[] = [
  { key: 'facebook_id', width: 200 },
  { key: 'remaining_amount', width: 150 },
  { key: 'card_balance', width: 150 },
]

const SETTINGS_CAMPAIGNS_KEYS: KaccCatalogSettingsDefault<keyof KaccCatalogCampaigns>[] = [
  { key: 'campaign_id', width: 150 },
  { key: 'campaign', width: 400 },
  { key: 'campaign_group_id', width: 150 },
  { key: 'campaign_group', width: 150 },
  { key: 'affiliate_network_id', width: 150 },
  { key: 'affiliate_network', width: 150 },
]

const SETTINGS_OFFERS_KEYS: KaccCatalogSettingsDefault<keyof KaccCatalogOffer>[] = [
  { key: 'offer_id', width: 100 },
  { key: 'offer', width: 500 },
  { key: 'offer_group_id', width: 150 },
  { key: 'offer_group', width: 150 },
  { key: 'stream_id', width: 150 },
  { key: 'clicks', width: 150 },
  { key: 'campaign_unique_clicks', width: 150 },
  { key: 'conversions', width: 150 },
  { key: 'roi_confirmed', width: 150 },
  { key: 'roi', width: 150 },
  { key: 'sale_revenue', width: 150 },
  { key: 'revenue', width: 150 },
  { key: 'cost', width: 150 },
  { key: 'profitability', width: 150 },
  { key: 'profit_confirmed', width: 150 },
  { key: 'profit', width: 150 },
  { key: 'leads', width: 150 },
  { key: 'sales', width: 150 },
  { key: 'rejected', width: 150 },
  { key: 'approve', width: 150 },
  { key: 'cr', width: 150 },
  { key: 'crs', width: 150 },
  { key: 'epc_confirmed', width: 150 },
  { key: 'uepc_confirmed', width: 150 },
  { key: 'cps', width: 150 },
  { key: 'cpa', width: 150 },
  { key: 'cpl', width: 150 },
  { key: 'cpc', width: 150 },
  { key: 'ucpc', width: 150 },
  { key: 'ecpc', width: 150 },
  { key: 'ecpm', width: 150 },
  { key: 'ecpm_confirmed', width: 150 },
  { key: 'ec', width: 150 },
  { key: 'ec_confirmed', width: 150 },
  { key: 'lead_revenue', width: 150 },
  { key: 'rejected_revenue', width: 150 },
  { key: 'rebills', width: 150 },
  { key: 'stream_unique_clicks', width: 150 },
  { key: 'global_unique_clicks', width: 150 },
  { key: 'uc_campaign_rate', width: 150 },
  { key: 'uc_stream_rate', width: 150 },
  { key: 'uc_global_rate', width: 150 },
  { key: 'bots', width: 150 },
  { key: 'bot_share', width: 150 },
  { key: 'proxies', width: 150 },
  { key: 'empty_referrers', width: 150 },
  { key: 'crl', width: 150 },
  { key: 'epc', width: 150 },
  { key: 'uepc', width: 150 },
  { key: 'epc_hold', width: 150 },
  { key: 'uepc_hold', width: 150 },
  { key: 'landing_clicked_period', width: 150 },
  { key: 'lp_views', width: 150 },
  { key: 'lp_clicks', width: 150 },
  { key: 'lp_ctr', width: 150 },
  { key: 'deposits', width: 150 },
  { key: 'deposit_revenue', width: 150 },
  { key: 'reg_revenue', width: 150 },
  { key: 'regs', width: 150 },
  { key: 'ucr_regs', width: 150 },
  { key: 'cr_regs_to_deps', width: 150 },
]

const SETTINGS_GROUPS_KEYS: (keyof KaccCatalogSettingsFilter)[] = [
  'anty',
  'facebook',
  'campaigns',
  'offers',
]

export const SETTINGS_DEFAULT: KaccCatalogSettingsFilter = {
  anty: SETTINGS_ANTY_KEYS.map(value => ({
    key: value.key,
    checked: true,
    uncheckable: value.key === 'anty_id',
    width: value.width,
  })),

  facebook: SETTINGS_FACEBOOK_KEYS.map(value => ({
    key: value.key,
    checked: true,
    uncheckable: value.key === 'facebook_id',
    width: value.width,
  })),

  campaigns: SETTINGS_CAMPAIGNS_KEYS.map(value => ({
    key: value.key,
    checked: true,
    uncheckable: value.key === 'campaign_id',
    width: value.width,
  })),

  offers: SETTINGS_OFFERS_KEYS.map(value => ({
    key: value.key,
    checked: true,
    uncheckable: value.key === 'offer_id',
    width: value.width,
  })),
}

export const SETTINGS_DEFAULT_GROUPS: KaccCatalogSettingsGroup[] = SETTINGS_GROUPS_KEYS.map(
  value => ({
    key: value,
    checked: true,
  }),
)

export const SETTINGS_PRICE_COLOR_KEYS: Partial<keyof KaccCatalogFacebook | keyof KaccCatalogOffer>[] = [
  'remaining_amount',
  'card_balance',
  'roi',
  'roi_confirmed',
  'revenue',
  'sale_revenue',
  'profit',
  'profit_confirmed',
  'lead_revenue',
  'rejected_revenue',
]

export const SETTINGS_FORMAT_KEYS: KaccCatalogSettingsFormat[] = [
  { key: 'anty_id', format: 'number' },
  { key: 'anty', format: 'string' },
  { key: 'facebook_id', format: 'number' },
  { key: 'remaining_amount', format: 'currency' },
  { key: 'card_balance', format: 'currency' },
  { key: 'campaign_id', format: 'number' },
  { key: 'campaign', format: 'string' },
  { key: 'campaign_group_id', format: 'number' },
  { key: 'campaign_group', format: 'string' },
  { key: 'affiliate_network_id', format: 'number' },
  { key: 'affiliate_network', format: 'string' },
  { key: 'offer_id', format: 'number' },
  { key: 'offer', format: 'string' },
  { key: 'offer_group_id', format: 'number' },
  { key: 'offer_group', format: 'string' },
  { key: 'stream_id', format: 'number' },
  { key: 'clicks', format: 'number' },
  { key: 'campaign_unique_clicks', format: 'number' },
  { key: 'conversions', format: 'number' },
  { key: 'roi_confirmed', format: 'percent' },
  { key: 'roi', format: 'percent' },
  { key: 'sale_revenue', format: 'currency' },
  { key: 'revenue', format: 'currency' },
  { key: 'cost', format: 'currency' },
  { key: 'profitability', format: 'number' },
  { key: 'profit_confirmed', format: 'currency' },
  { key: 'profit', format: 'currency' },
  { key: 'leads', format: 'number' },
  { key: 'sales', format: 'number' },
  { key: 'rejected', format: 'number' },
  { key: 'approve', format: 'percent' },
  { key: 'cr', format: 'percent' },
  { key: 'crs', format: 'percent' },
  { key: 'epc_confirmed', format: 'currency' },
  { key: 'uepc_confirmed', format: 'currency' },
  { key: 'cps', format: 'currency' },
  { key: 'cpa', format: 'currency' },
  { key: 'cpl', format: 'currency' },
  { key: 'cpc', format: 'currency' },
  { key: 'ucpc', format: 'currency' },
  { key: 'ecpc', format: 'currency' },
  { key: 'ecpm', format: 'currency' },
  { key: 'ecpm_confirmed', format: 'currency' },
  { key: 'ec', format: 'currency' },
  { key: 'ec_confirmed', format: 'currency' },
  { key: 'lead_revenue', format: 'currency' },
  { key: 'rejected_revenue', format: 'currency' },
  { key: 'rebills', format: 'number' },
  { key: 'stream_unique_clicks', format: 'number' },
  { key: 'global_unique_clicks', format: 'number' },
  { key: 'uc_campaign_rate', format: 'percent' },
  { key: 'uc_stream_rate', format: 'percent' },
  { key: 'uc_global_rate', format: 'percent' },
  { key: 'bots', format: 'number' },
  { key: 'bot_share', format: 'percent' },
  { key: 'proxies', format: 'number' },
  { key: 'empty_referrers', format: 'number' },
  { key: 'crl', format: 'percent' },
  { key: 'epc', format: 'currency' },
  { key: 'uepc', format: 'currency' },
  { key: 'epc_hold', format: 'currency' },
  { key: 'uepc_hold', format: 'currency' },
  { key: 'landing_clicked_period', format: 'time' },
  { key: 'lp_views', format: 'number' },
  { key: 'lp_clicks', format: 'number' },
  { key: 'lp_ctr', format: 'percent' },
  { key: 'deposits', format: 'number' },
  { key: 'deposit_revenue', format: 'currency' },
  { key: 'reg_revenue', format: 'currency' },
  { key: 'regs', format: 'number' },
  { key: 'ucr_regs', format: 'currency' },
  { key: 'cr_regs_to_deps', format: 'currency' },
]
