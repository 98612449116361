import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import { ProtectedRoute } from '../../shared/ui/protected-route'
import { useUserStore } from '../../entities/user'
import { Dashboard } from '../../pages/dashboard'
import { DomainsWatcher } from '../../pages/domains-watcher'
import { NotFound } from '../../pages/not-found'
import { SignIn } from '../../pages/auth/sign-in'
import { WatchCatCatalog } from '../../pages/watch-cat/catalog'
import { WatchCatInfo } from '../../pages/watch-cat/info'
import { DomainsGenerator } from '../../pages/domains-generator/generator'
import { DomainsGeneratorSettings } from '../../pages/domains-generator/settings'
import { UserSettingsIntegrations, UserSettingsManageMembers, UserSettingsPassword } from '../../pages/user/settings'
import { KaccCatalog } from '../../pages/kacc/catalog'
import { WhiteGenerator } from '../../pages/white-generator'
import { IntegrationList } from '../../pages/kacc/integrations'
import { CustomReportsCatalog } from '../../pages/custom-reports/catalog'
import { CustomReportsInfo } from '../../pages/custom-reports/info'
import { CampaignChangeHistory } from '../../pages/kacc/campaign-history'
import { Recover } from '../../pages/auth/recover'
import { RecoverToken } from '../../pages/auth/recover-token'
import { RegisterToken } from '../../pages/auth/register-token'
import { CampaignsList } from '../../pages/kacc/campaings-list'
import { UserIntegrations } from '../../pages/user/settings/ui/integrations-new'
import { useAppStore } from '../../entities/app'
import { ENVIRONMENT } from '../../shared/configs/enums/common'

export function PagesWithRoutes(): React.ReactNode {
  const { info: appInfo, infoLoading: appInfoLoading } = useAppStore()
  const { isLoggedIn } = useUserStore()

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
              <Dashboard/>
            </ProtectedRoute>
          }
        />

        <Route path="/custom-reports">
          <Route
            index
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <CustomReportsCatalog/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/custom-reports/info/:reportId"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <CustomReportsInfo/>
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
              <Dashboard/>
            </ProtectedRoute>
          }
        />

        <Route
          path="/domains-watcher"
          element={
            <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
              <DomainsWatcher/>
            </ProtectedRoute>
          }
        />

        <Route path="/kacc">
          <Route
            index
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <KaccCatalog/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/kacc/integrations"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <IntegrationList/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/kacc/campaign-list"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <CampaignsList/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/kacc/campaign-changes"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <CampaignChangeHistory/>
              </ProtectedRoute>
            }
          />
        </Route>

        {
          !appInfoLoading && appInfo?.environment !== ENVIRONMENT.PRODUCTION && (
            <Route path="/subdomains-generator">
              <Route
                index
                element={
                  <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                    <DomainsGenerator/>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/subdomains-generator/settings"
                element={
                  <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                    <DomainsGeneratorSettings/>
                  </ProtectedRoute>
                }
              />
            </Route>
          )
        }

        <Route path="/watch-cat">
          <Route
            index
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <WatchCatCatalog/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/watch-cat/offer/:offerId"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <WatchCatInfo/>
              </ProtectedRoute>
            }
          />
        </Route>

        {
          !appInfoLoading && appInfo?.environment !== ENVIRONMENT.PRODUCTION && (
            <Route path="/white-generator">
              <Route
                index
                element={
                  <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                    <WhiteGenerator/>
                  </ProtectedRoute>
                }
              />
            </Route>
          )
        }

        <Route path="/settings">
          <Route index element={<Navigate to={'/settings/integrations'}/>}/>
          <Route
            path="/settings/integrations"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <UserSettingsIntegrations/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/integrations-new"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <UserIntegrations/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/password"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <UserSettingsPassword/>
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings/members"
            element={
              <ProtectedRoute isAllowed={isLoggedIn} redirectTo="/signin">
                <UserSettingsManageMembers/>
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/signin"
          element={
            <ProtectedRoute isAllowed={true} redirectTo="/">
              <SignIn/>
            </ProtectedRoute>
          }
        />

        <Route
          path="/recover"
          element={
            <ProtectedRoute isAllowed={!isLoggedIn} redirectTo="/">
              <Recover/>
            </ProtectedRoute>
          }
        />

        <Route
          path="/recover/:token"
          element={
            <ProtectedRoute isAllowed={!isLoggedIn} redirectTo="/">
              <RecoverToken/>
            </ProtectedRoute>
          }
        />

        <Route
          path="/register/:token"
          element={
            <ProtectedRoute isAllowed={!isLoggedIn} redirectTo="/">
              <RegisterToken/>
            </ProtectedRoute>
          }
        />

        <Route
          path="*" element={
            <NotFound/>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}
