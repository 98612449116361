/* eslint-disable max-len */
import { Icon } from '../../icon'

export function AntyDolphinLogo() {
  return (
    <Icon>
      <svg
        width="100" height="100"
        viewBox="0 0 100 100" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="100" height="100"
          fill="white"/>
        <path
          d="M7 43H11.0709C13.0177 43 14.4926 43.5173 15.4956 44.5506C16.4984 45.5848 17 47.0612 17 48.9798V50.0197C17 51.914 16.4925 53.3848 15.4776 54.4306C14.4627 55.4769 12.9938 56 11.0706 56H7V43ZM9.12384 44.9684V54.0311H11.0709C12.2979 54.0311 13.2212 53.7031 13.8406 53.0472C14.4604 52.3908 14.7697 51.3818 14.7697 50.0197V48.9798C14.7697 47.631 14.457 46.6247 13.8317 45.9619C13.2064 45.3002 12.2857 44.9684 11.0706 44.9684H9.12384Z"
          fill="#B645EE"/>
        <path
          d="M21.9997 56C20.744 56 19.7637 55.6293 19.0581 54.8881C18.3524 54.1469 18 53.11 18 51.7781V51.2219C18 50.5617 18.0916 49.9713 18.2749 49.4498C18.4581 48.9287 18.7249 48.4856 19.0748 48.1209C19.4247 47.7562 19.8442 47.4782 20.3331 47.2869C20.8218 47.096 21.3774 47 21.9997 47C23.2553 47 24.2357 47.3712 24.9414 48.112C25.647 48.8532 26 49.89 26 51.2219V51.7781C26 52.4383 25.9084 53.0287 25.725 53.5502C25.5415 54.0713 25.2751 54.5144 24.9249 54.8792C24.5748 55.2439 24.1525 55.5219 23.6581 55.7131C23.1637 55.9038 22.6108 56 21.9997 56ZM21.9997 54.1232C22.633 54.1232 23.1192 53.9323 23.4581 53.5501C23.797 53.1676 23.9666 52.5772 23.9666 51.7779V51.2217C23.9666 50.4343 23.7913 49.8464 23.4416 49.4585C23.0917 49.0706 22.6108 48.8766 21.9999 48.8766C21.3665 48.8766 20.8804 49.0676 20.5417 49.4498C20.2025 49.8322 20.0334 50.4226 20.0334 51.2219V51.7781C20.0334 52.5655 20.208 53.1534 20.5582 53.5413C20.9083 53.9293 21.3888 54.1232 21.9997 54.1232Z"
          fill="#B645EE"/>
        <path
          d="M28.8096 55.2107C28.2698 54.6843 28 53.9263 28 52.9355V43H30.2094V52.7128C30.2094 53.1951 30.3174 53.5485 30.5333 53.7713C30.7492 53.9941 31.0792 54.1057 31.5239 54.1057H32V56H31.1237C30.1206 55.9997 29.3492 55.7364 28.8096 55.2107Z"
          fill="#B645EE"/>
        <path
          d="M37.5567 56.4982C36.4666 56.4982 35.6202 56.1373 35.0173 55.4161V60H33V47.1652H34.5825L34.7739 48.4123C35.0752 47.9356 35.4608 47.5809 35.9302 47.3484C36.3999 47.1166 36.9417 47 37.5567 47C38.0551 47 38.516 47.0949 38.9396 47.2842C39.3625 47.4743 39.7242 47.7554 40.0259 48.1277C40.3277 48.501 40.565 48.9654 40.7391 49.5216C40.9126 50.0778 41 50.7222 41 51.4555V52.0425C41 52.7758 40.9126 53.4175 40.7391 53.9676C40.565 54.5176 40.3245 54.9825 40.017 55.3611C39.7102 55.7403 39.3478 56.0244 38.93 56.2133C38.5128 56.403 38.0551 56.4982 37.5567 56.4982ZM36.9477 54.5176C37.562 54.5176 38.0376 54.3223 38.3738 53.9311C38.7099 53.5399 38.8783 52.9105 38.8783 52.0425V51.4555C38.8783 50.612 38.7042 49.9889 38.3565 49.5853C38.0089 49.1822 37.5394 48.9804 36.9479 48.9804C36.3917 48.9804 35.9365 49.182 35.5825 49.5853C35.2289 49.9889 35.0405 50.5756 35.0173 51.3458V52.0425C35.0173 52.849 35.2002 53.4631 35.5651 53.8851C35.9302 54.3068 36.3914 54.5176 36.9477 54.5176Z"
          fill="#B645EE"/>
        <path
          d="M43 43H45.0626V47.5129C45.6547 46.8691 46.4783 46.5469 47.5334 46.5469C48.6237 46.5469 49.4741 46.8843 50.0844 47.5593C50.6948 48.2343 51 49.2522 51 50.6143V56H48.938V50.6143C48.938 49.9334 48.7809 49.4193 48.4666 49.0728C48.1523 48.7263 47.7232 48.5528 47.178 48.5528C46.5377 48.5528 46.031 48.7322 45.658 49.0914C45.2843 49.4507 45.0854 49.9272 45.0626 50.5212V56H43V43Z"
          fill="#B645EE"/>
        <path
          d="M54.5004 45.7467C54.0734 45.7467 53.7168 45.6158 53.4299 45.3529C53.1431 45.0907 53 44.7641 53 44.3734C53 43.9827 53.1431 43.6563 53.4299 43.3938C53.7168 43.1316 54.0734 43 54.5004 43C54.9134 43 55.2671 43.1314 55.5605 43.3938C55.8533 43.6561 56 43.9827 56 44.3734C56 44.7641 55.8533 45.0904 55.5605 45.3529C55.2671 45.6158 54.9134 45.7467 54.5004 45.7467ZM53.3404 46.845H55.6603V56H53.3404V46.845Z"
          fill="#B645EE"/>
        <path
          d="M58 47.1593H59.6179L59.7776 48.1494C60.4408 47.3834 61.3596 47 62.5334 47C63.6231 47 64.4741 47.3212 65.0845 47.9639C65.6948 48.6065 66 49.5755 66 50.8723V56H63.9374V50.8725C63.9374 50.2242 63.7802 49.7348 63.4659 49.405C63.1523 49.0752 62.7225 48.91 62.178 48.91C61.5377 48.91 61.031 49.0807 60.6573 49.4228C60.2837 49.7647 60.0854 50.2184 60.062 50.784V56H58V47.1593Z"
          fill="#B645EE"/>
        <path
          d="M71 46.7113H70.5545C69.8191 46.7113 69.4514 47.1557 69.4514 48.0447V49.4888C69.4514 49.8224 69.4081 50.1205 69.3208 50.3833C69.2338 50.6467 69.0418 50.852 68.745 51.0002C68.8934 51.0818 69.0144 51.1743 69.1078 51.278C69.2015 51.3819 69.2739 51.4982 69.3254 51.6278C69.3772 51.7575 69.4109 51.8964 69.4272 52.0445C69.4432 52.1926 69.4514 52.3484 69.4514 52.5111V53.9555C69.4514 54.8445 69.8191 55.2889 70.5545 55.2889H71V56H70.5545C69.3676 56 68.7741 55.3183 68.7741 53.9555V52.5109C68.7741 52.1185 68.6982 51.8352 68.5466 51.6611C68.3951 51.487 68.2126 51.3815 68 51.3443V50.6556C68.2126 50.6186 68.3951 50.5129 68.5466 50.3388C68.6982 50.1647 68.7741 49.8815 68.7741 49.4887V48.0446C68.7741 46.6818 69.3676 46 70.5545 46H71V46.7113Z"
          fill="#28334A"/>
        <path
          d="M73.5898 54C73.077 54 72.6835 53.8504 72.4104 53.5507C72.1369 53.2512 72 52.8368 72 52.3074C72 52.0271 72.0395 51.7723 72.1181 51.5425C72.1967 51.313 72.3231 51.1206 72.4977 50.9649C72.6719 50.8093 72.8957 50.6885 73.1696 50.6028C73.4427 50.5173 73.7711 50.4744 74.154 50.4744H75.3232V49.9491C75.3232 49.5291 75.2322 49.2196 75.0516 49.0211C74.8699 48.8229 74.5709 48.7234 74.154 48.7234C73.7779 48.7234 73.4957 48.8071 73.3076 48.9745C73.1199 49.1421 73.0119 49.3695 72.9849 49.6575H72.2566C72.2701 49.4161 72.3183 49.1946 72.3999 48.9921C72.4819 48.7896 72.6015 48.6146 72.7591 48.4667C72.9164 48.3188 73.1113 48.2041 73.3437 48.1223C73.5762 48.0409 73.846 48 74.154 48C74.7623 48 75.222 48.1712 75.5335 48.5135C75.8443 48.856 76 49.3348 76 49.9493V53.9184H75.4767L75.3744 52.8212C75.1965 53.1946 74.9523 53.4845 74.6412 53.6908C74.3297 53.8968 73.9795 54 73.5898 54ZM73.6409 53.2763C73.8734 53.2763 74.0924 53.2277 74.2974 53.1304C74.5024 53.0332 74.6822 52.9009 74.8357 52.7334C74.9896 52.5662 75.1092 52.3716 75.195 52.1498C75.2804 51.928 75.3229 51.6926 75.3229 51.4435V51.1751H74.154C73.627 51.1751 73.2527 51.2802 73.0308 51.4901C72.8084 51.7003 72.6975 51.9729 72.6975 52.3074C72.6975 52.6031 72.7776 52.8384 72.9386 53.0137C73.0992 53.1885 73.3332 53.2763 73.6409 53.2763Z"
          fill="#28334A"/>
        <path
          d="M77.2002 48.0826H77.6976L77.7757 49.0531C77.9385 48.714 78.1579 48.4538 78.4345 48.2721C78.7108 48.0907 79.0311 48 79.3954 48C79.9548 48 80.3953 48.2053 80.7174 48.6152C81.0395 49.0254 81.2002 49.6368 81.2002 50.4496V54H80.5564V50.4498C80.5564 49.874 80.4458 49.4439 80.2246 49.1596C80.0035 48.8755 79.6946 48.7335 79.2977 48.7335C78.8686 48.7335 78.5222 48.8835 78.2588 49.1832C77.995 49.4833 77.8569 49.8814 77.844 50.3786V54H77.2002V48.0826Z"
          fill="#28334A"/>
        <path
          d="M82 48.531H82.8997V47H83.56V48.531H84.8999V49.1874H83.56V52.4687C83.56 53.052 83.8065 53.3436 84.2998 53.3436H85V54H84.2198C83.7866 54 83.458 53.8779 83.235 53.6337C83.0116 53.3894 82.8997 53.0302 82.8997 52.5563V49.1875H82V48.531Z"
          fill="#28334A"/>
        <path
          d="M87.7618 53.7143L86 48H86.6299L88.0681 52.7771L89.4044 48H90L87.7022 56H87.1063L87.7618 53.7143Z"
          fill="#28334A"/>
        <path
          d="M91 55.2887H91.4452C92.1807 55.2887 92.5485 54.8443 92.5485 53.9553V52.5109C92.5485 52.1776 92.5918 51.8795 92.6791 51.6164C92.7661 51.3537 92.9581 51.1479 93.2549 50.9998C93.1061 50.9185 92.9854 50.8257 92.8921 50.7219C92.7984 50.6184 92.7259 50.5017 92.6745 50.3719C92.6226 50.2424 92.5889 50.1035 92.5729 49.9554C92.5566 49.8073 92.5485 49.652 92.5485 49.4886V48.0444C92.5485 47.1554 92.1807 46.711 91.4452 46.711H91V46H91.4452C92.6322 46 93.2261 46.6818 93.2261 48.0446V49.4887C93.2261 49.8814 93.3017 50.1651 93.4533 50.3391C93.6049 50.513 93.7874 50.6187 94 50.6557V51.3444C93.7874 51.3816 93.6049 51.487 93.4533 51.6611C93.3017 51.835 93.2261 52.1184 93.2261 52.511V53.9554C93.2261 55.3182 92.6322 56 91.4452 56H91V55.2887Z"
          fill="#28334A"/>
      </svg>
    </Icon>
  )
}
