import React from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { t } from 'i18next'

import type { OptionsCommentProps } from './types'

export const OptionsComment = ({ value, onInputChange, error }: OptionsCommentProps) => {

  return (
    <Autocomplete
      freeSolo
      autoFocus={ false }
      defaultValue={ value ?? '' }
      value={ value ?? '' }
      inputValue={ value ?? '' }
      clearOnBlur={ true }
      open={ false }
      options={ [] }
      noOptionsText={t('subdomains-generator:No options')}
      onInputChange={ (_e, value) => onInputChange(value) }
      renderInput={ (params) => (
        <TextField
          { ...params }
          error={ error }
          label={ t('subdomains-generator:Enter comment') }
          InputProps={ {
            ...params.InputProps,
          } }
        />
      ) }
      slotProps={ {
        clearIndicator: {
          tabIndex: 0,
        },
      } }
      sx={ { width: '200px' } }
    />
  )
}
