import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SortOrder } from '../configs/enums/pagination'

export const SortTypeParam = 'sort'
export const SortColumnParam = 'column'

export type SortProps = {
    setAsc: (column: string) => void
    setDesc: (column: string) => void
    toggleSort: (column: string) => void
    sortActive: SortOrder | null
    sortColumn: string | null
}

export function useSort(): SortProps {
  const [search, setSearch] = useSearchParams()
  const [sortActive, setSortActive] = useState<SortOrder | null>(null)
  const [sortColumn, setSortColumn] = useState<string | null>(null)

  const setAsc = (column: string) => {
    search.set(SortColumnParam, column)
    search.set(SortTypeParam, SortOrder.ASC)
    setSearch(search, { replace: true })
  }

  const setDesc = (column: string) => {
    search.set(SortColumnParam, column)
    search.set(SortTypeParam, SortOrder.DESC)
    setSearch(search, { replace: true })
  }

  const toggleSort = (column: string) => {
    search.set(SortColumnParam, column)
    search.set(SortTypeParam, sortActive === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC)
    setSearch(search, { replace: true })
  }

  useEffect(() => {
    const sortType = search.get(SortTypeParam)
    const sortColumn = search.get(SortColumnParam)

    // if search has SortTypeParam query,
    // and it's value is in SortType
    if (sortType && Object.values(SortOrder).includes(sortType as SortOrder))
      setSortActive(sortType as SortOrder)
    else {
      // else remove search queries: column, sort
      search.delete(SortColumnParam)
      search.delete(SortTypeParam)
      setSearch(search, { replace: true })
    }

    setSortColumn(sortColumn as string)

  }, [search, sortActive, sortColumn])

  return {
    setAsc,
    setDesc,
    toggleSort,
    sortActive,
    sortColumn,
  }
}
