/* eslint-disable max-len */
import { Icon } from '../../icon'

export function FlexLogo() {
  return (
    <Icon>
      <svg
        width="100" height="100"
        viewBox="0 0 100 100" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4485_20061)">
          <rect
            width="100" height="100"
            fill="white"/>
          <path
            d="M66.9672 83L17.3545 43.0911L39.1503 16L88.7747 55.9089L66.9672 83ZM21.0047 42.739L66.5564 79.3378L85.0892 56.2728L39.5493 19.6622L21.0047 42.739Z"
            fill="#04694B"/>
          <rect
            width="100" height="27"
            transform="translate(1 36)" fill="white"/>
          <path
            d="M10.8992 56.733H9V42.2272H18.2779V43.8565H10.8992V48.8432H17.6449V50.4527H10.8992V56.733Z"
            fill="#28334A"/>
          <path d="M20.2178 56.733V42.2272H22.0651V56.733H20.2178Z" fill="#28334A"/>
          <path
            d="M31.8317 53.7311H33.6375C33.4689 54.6494 32.9238 55.4663 32.1223 56.0023C31.2306 56.6076 30.1557 56.9196 29.0608 56.891C28.37 56.9229 27.6803 56.8103 27.0402 56.5612C26.4001 56.3121 25.8252 55.9326 25.3558 55.4493C24.3989 54.3416 23.9171 52.9308 24.0067 51.4995C23.9272 50.0694 24.4079 48.6621 25.3558 47.5497C25.8126 47.074 26.374 46.6999 27 46.4541C27.626 46.2084 28.3013 46.0969 28.9777 46.1277C29.6372 46.0985 30.295 46.2112 30.9023 46.4574C31.5097 46.7036 32.0508 47.0769 32.4855 47.5497C33.3904 48.6198 33.8486 49.9699 33.7724 51.3415V52.0031H25.8955V52.0525C25.8501 52.9306 26.1581 53.7922 26.7569 54.4618C27.0558 54.7653 27.4202 55.0035 27.8251 55.1604C28.2299 55.3172 28.6656 55.3888 29.1023 55.3703C29.7249 55.3902 30.3395 55.232 30.8665 54.9161C31.329 54.6459 31.6721 54.2246 31.8317 53.7311ZM28.9466 47.6188C28.5585 47.6148 28.1737 47.6874 27.8165 47.8318C27.4593 47.9763 27.1374 48.1895 26.871 48.4581C26.2979 49.0447 25.9694 49.8101 25.9474 50.6108H31.8732C31.8901 49.8166 31.5939 49.0451 31.043 48.4482C30.777 48.1744 30.452 47.9585 30.0901 47.8154C29.7283 47.6722 29.3382 47.6051 28.9466 47.6188Z"
            fill="#28334A"/>
          <path
            d="M39.3032 52.7535H39.2513L36.6049 56.7033H34.5293L38.2758 51.4797L34.5916 46.2462H36.6672L39.3136 50.196L41.9184 46.2462H43.994L40.2891 51.4007L43.9421 56.7033H41.8665L39.3032 52.7535Z"
            fill="#28334A"/>
          <path
            d="M52.5246 56.9897C51.5248 57.0422 50.5246 56.8935 49.5893 56.5532C48.654 56.2128 47.8047 55.6886 47.0969 55.0148C45.705 53.4911 44.9937 51.5104 45.1147 49.4949C44.9892 47.4801 45.7054 45.4997 47.1072 43.9849C47.8135 43.3118 48.6611 42.788 49.5946 42.4477C50.528 42.1074 51.5264 41.9583 52.5246 42.01C53.7245 41.9893 54.9124 42.2397 55.9908 42.7407C56.9895 43.1985 57.8394 43.9048 58.4504 44.7848C59.0656 45.6813 59.4004 46.7258 59.4156 47.7965H55.638C55.5756 47.0121 55.205 46.2788 54.6002 45.7426C54.042 45.2308 53.2996 44.9412 52.5246 44.9329C52.0278 44.9118 51.5334 45.0102 51.0872 45.219C50.6409 45.4279 50.2572 45.7404 49.9716 46.1277C49.3049 47.1138 48.9818 48.2747 49.0479 49.4455C48.9856 50.6253 49.3083 51.7945 49.9716 52.793C50.2525 53.1876 50.6341 53.5079 51.0806 53.7238C51.5271 53.9397 52.024 54.0441 52.5246 54.0273C53.2885 54.0311 54.0272 53.7675 54.6002 53.2867C55.1933 52.7545 55.5623 52.0349 55.638 51.2625H59.3637C59.3476 52.0544 59.1541 52.834 58.7962 53.5492C58.4383 54.2644 57.9241 54.8987 57.2881 55.4098C55.9537 56.468 54.2611 57.0294 52.5246 56.9897Z"
            fill="#04694B"/>
          <path
            d="M64.3359 56.891C63.3383 56.9291 62.3615 56.6122 61.5961 56.0023C61.2336 55.7089 60.9447 55.342 60.7508 54.9284C60.5568 54.5149 60.4627 54.0653 60.4753 53.6126C60.4549 53.152 60.553 52.6936 60.761 52.2771C60.9689 51.8607 61.2805 51.4989 61.6688 51.223C62.6982 50.5869 63.8973 50.2443 65.1246 50.2355L67.8125 50.0775V49.4456C67.8217 49.2605 67.7874 49.0758 67.712 48.9049C67.6366 48.7341 67.522 48.5814 67.3767 48.4581C67.0348 48.2104 66.6125 48.0846 66.1832 48.1026C65.7521 48.0839 65.3262 48.1982 64.969 48.4285C64.8207 48.5263 64.6952 48.6522 64.6006 48.7983C64.5059 48.9445 64.4441 49.1077 64.4189 49.2777H61.0253C61.0405 48.7473 61.1854 48.2275 61.4484 47.7596C61.7114 47.2917 62.0853 46.8885 62.5405 46.5819C63.662 45.8644 65.0002 45.5175 66.3492 45.5945C67.6677 45.5291 68.9725 45.8759 70.0646 46.5819C70.5083 46.889 70.8675 47.2937 71.1114 47.7613C71.3554 48.229 71.4769 48.7458 71.4656 49.2678V56.7725H67.8125V55.1728H67.7399C67.4254 55.7177 66.9439 56.1586 66.3596 56.4367C65.7337 56.742 65.0392 56.8979 64.3359 56.891ZM65.6643 54.4421C66.2006 54.4681 66.7305 54.322 67.1691 54.0273C67.3666 53.8948 67.5274 53.7186 67.6377 53.514C67.7479 53.3093 67.8044 53.0823 67.8022 52.8523V52.0524L65.7266 52.1808C65.3196 52.1835 64.9239 52.3079 64.5954 52.5363C64.4703 52.6303 64.3696 52.7505 64.3012 52.8875C64.2329 53.0245 64.1986 53.1746 64.201 53.3263C64.1965 53.482 64.2309 53.6366 64.3012 53.7773C64.3715 53.9181 64.4759 54.041 64.6057 54.136C64.9122 54.3487 65.2852 54.4566 65.6643 54.4421Z"
            fill="#04694B"/>
          <path
            d="M73.2822 56.733V45.7821H76.9249V47.8558H76.9976C77.0839 47.2329 77.4072 46.6617 77.9064 46.2504C78.4056 45.8392 79.0459 45.6163 79.7062 45.6241C80.0272 45.6153 80.3472 45.6621 80.6506 45.7624V48.7939C80.2702 48.6543 79.8647 48.5872 79.4571 48.5964C79.1282 48.5765 78.7985 48.6205 78.4881 48.7258C78.1776 48.831 77.8929 48.9953 77.6514 49.2086C77.4302 49.4476 77.2612 49.7261 77.1543 50.0279C77.0474 50.3296 77.0047 50.6486 77.0287 50.9663V56.7231L73.2822 56.733Z"
            fill="#04694B"/>
          <path
            d="M85.735 56.8909C85.0889 56.9168 84.4459 56.7938 83.8604 56.5325C83.275 56.2712 82.7646 55.8793 82.3725 55.39C81.4865 54.1739 81.0552 52.7099 81.1479 51.2328C81.0489 49.7703 81.4732 48.3189 82.3518 47.1151C82.7544 46.6328 83.2701 46.2468 83.8575 45.9879C84.445 45.729 85.0879 45.6044 85.735 45.6241C86.4923 45.6031 87.2383 45.803 87.8728 46.1968C88.4653 46.5566 88.923 47.0854 89.1805 47.7076H89.2531V42.2272H92.9996V56.733H89.2843V54.8568H89.2116C88.9629 55.4707 88.5065 55.9883 87.9144 56.3281C87.2587 56.7086 86.5029 56.9039 85.735 56.8909ZM88.7238 49.1789C88.4185 48.781 87.9603 48.5141 87.449 48.4364C86.9377 48.3587 86.4148 48.4765 85.9944 48.7642C85.827 48.8757 85.6797 49.0124 85.5586 49.169C85.1433 49.7894 84.9436 50.5183 84.9878 51.2526C84.9435 51.99 85.1431 52.7221 85.5586 53.346C85.7431 53.581 85.9827 53.7718 86.2584 53.9032C86.534 54.0346 86.8381 54.103 87.1464 54.103C87.4547 54.103 87.7588 54.0346 88.0344 53.9032C88.31 53.7718 88.5497 53.581 88.7342 53.346C89.1546 52.7237 89.358 51.9912 89.3154 51.2526C89.36 50.5155 89.1524 49.7845 88.7238 49.169V49.1789Z"
            fill="#04694B"/>
        </g>
        <defs>
          <clipPath id="clip0_4485_20061">
            <rect
              width="100" height="100"
              fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </Icon>
  )
}
