import { useState } from 'react'
import { t } from 'i18next'
// import { useTheme } from '@mui/material/styles'

import { Button } from '../../../../shared/ui/button'

import type { DomainsGeneratorData } from '../../../../entities/domains-generator/generator'
import type { TableSelectUnselectAllProps } from './types'

export const TableSelectUnselectAll = ({ data, handleMainData }: TableSelectUnselectAllProps) => {
  // const theme = useTheme()
  const [ selected, setSelected ] = useState<boolean>(false)

  const handleSelectAction = (data: DomainsGeneratorData, selected: boolean): DomainsGeneratorData => {
    data.domains = data?.domains
      ?.map((domain) => ({
        ...domain, selected: selected,
      }))
    return data
  }

  const handleClick = () => {
    if (selected) {
      data && handleMainData(handleSelectAction(data, false))
      setSelected(false)
    } else {
      data && handleMainData(handleSelectAction(data, true))
      setSelected(true)
    }
  }

  return (
    <Button
      size={ 'small' }
      onClick={handleClick}
      sx={(theme) => theme.customButtons.noShadowButton}
    >
      { selected
        ? t('subdomains-generator:Unselect all')
        : t('subdomains-generator:Select all')
      }
    </Button>
  )
}
