import styled from 'styled-components'

import { Pagination } from '../../../../shared/ui/pagination'
import { useCustomReportInfoStore } from '../../../../entities/custom-reports/info'
import { PageLimit } from '../../../../shared/configs/enums/pagination'

export function PaginationBlock() {
  const { catalogCount, catalogIsLoading } = useCustomReportInfoStore()

  return (
    !catalogIsLoading &&
    <SPaginationBlock>
      <Pagination
        count={catalogCount ?? 0}
        limits={Object.values(PageLimit).map(s => Number(s))}/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
    display: flex;
    gap: 20px;
`
