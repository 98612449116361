import React from 'react'
import { Card, CardContent, Typography, Box, Divider } from '@mui/material'
import { styled } from '@mui/system'
import { t } from 'i18next'

import { getFormattedDateStamp } from '../../../../shared/lib'

import type { DomainInfoCardProps } from '../types'

export const DomainInfoCard = ({ domain }: DomainInfoCardProps) => {

  const renderKeyValuePairs = (obj: any, parentKey = ''): React.ReactNode[] => {
    return Object.entries(obj || {}).map(([key, value]) => {
      const dateKeys = [
        'expire_date',
        'created_at',
        'updated_at',
        'domain_created_date',
        'domain_updated_date',
        'domain_expiration_date',
      ]
      const fullKey = parentKey ? `${parentKey}.${key}` : key
      const formattedKey = fullKey.replace(/\./g, '_') // Replace all dots with underscores

      if (dateKeys.includes(formattedKey)) {
        value = getFormattedDateStamp(value as string)
      }

      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        // If the value is an object, recurse and render nested key-value pairs
        return renderKeyValuePairs(value, fullKey)
      } else {
        return value ? (
          <KeyValueWrapper key={fullKey}>
            <strong>{t(`domains-watcher:${formattedKey}`)}{': '}</strong>{`${value}`}
          </KeyValueWrapper>
        ) : null
      }
    })
  }

  return (
    <Box p={2}>
      {/* Domain Information */}
      <>
        <CategoryCard>
          <CardContent>
            <SectionTitle>{t('domains-watcher:Domain Information')}</SectionTitle>
            <DividerStyled/>
            {renderKeyValuePairs({
              comment: domain?.comment,
              expire_date: domain?.expire_date,
              sub_domains_count: domain?.sub_domains_count,
              last_check: getFormattedDateStamp(domain?.last_check),
              created_at: getFormattedDateStamp(domain?.created_at),
              updated_at: getFormattedDateStamp(domain?.updated_at),
            })}
          </CardContent>
        </CategoryCard>
      </>

      {/* Keitaro Information */}
      {domain?.keitaro && (
        <>
          <CategoryCard>
            <CardContent>
              <SectionTitle>{t('domains-watcher:Keitaro Information')}</SectionTitle>
              <DividerStyled/>
              {renderKeyValuePairs(domain.keitaro)}
            </CardContent>
          </CategoryCard>
        </>
      )}

      {/* Whois Information */}
      {domain?.whois && (
        <>
          <CategoryCard>
            <CardContent>
              <SectionTitle>{t('domains-watcher:Whois Information')}</SectionTitle>
              <DividerStyled/>
              {renderKeyValuePairs(domain.whois)}
            </CardContent>
          </CategoryCard>
        </>
      )}
    </Box>
  )
}

// Styled components
const CategoryCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1), // Reduced padding for tighter fit
  wordWrap: 'break-word',
  width: '100%', // Ensure it fits well in a popup
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 600, // Slightly bolder for emphasis
  fontSize: '1rem',
  color: theme.palette.text.primary,
}))

const KeyValueWrapper = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: '0.875rem',
  color: theme.palette.text.secondary, // Softer color for less visual noise
  lineHeight: 1.5,
  '& strong': {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}))

const DividerStyled = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  backgroundColor: theme.palette.divider, // Soft divider for clean look
}))
