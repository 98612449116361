import React, { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import SendIcon from '@mui/icons-material/Send'
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp'
import { t } from 'i18next'

import { getArraySplitToNChunks, useDimensionsWindow } from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { useWhiteGeneratorStore } from '../../../../entities/white-generator/generator'
import { GenerationTemplate } from '../../../../entities/white-generator/generator/types'
import { STooltip } from '../../../watch-cat/catalog/ui/table'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableColumn,
  TableColumnWrapper,
  TableRow,
  TableValue,
  TableWrapper,
} from './table'

import type { TableDomainsProps } from '../types'

type ActionButtonsProps = {
  $buttonstyle: 'send' | 'openLink'
}

export const TableTemplates: FC<TableDomainsProps> = (props) => {
  const [columnsCount, setColumnsCount] = useState(1)
  const { screenSize } = useDimensionsWindow()
  const [selectedItem, setSelectedItem] = useState<{ pack: string; title: string } | null>(null)
  const [templatesNChunks, setTemplatesNChunks] = useState<any>()

  const {
    generations,
    generateLoading,
    lastGeneratedLoading,
    generateError,
    sendGeneratedLoading,
  } = useWhiteGeneratorStore()

  const hasData = (
    !generateLoading
    && generations?.pack
    && generations.pack?.length > 0
  )

  useEffect(() => {
    if (screenSize.width < 500)
      setColumnsCount(1)

    if (screenSize.width >= 500 && screenSize.width < 1100)
      setColumnsCount(2)

    if (screenSize.width >= 1100 && screenSize.width < 1600)
      setColumnsCount(3)

    if (screenSize.width >= 1600)
      setColumnsCount(4)
  }, [screenSize])

  useEffect(() => {
    if (generations?.pack) {
      const t = getArraySplitToNChunks(generations.pack, columnsCount)
      setTemplatesNChunks(t)
    }
  }, [generations?.pack, columnsCount])

  useEffect(() => {
    props.formik.setFieldValue('template', selectedItem)
  }, [selectedItem])

  const onClickHandler = (pack: string, title: string) => {
    setSelectedItem({ pack, title })
  }

  useEffect(() => {
    if (selectedItem !== null) {
      props.formik.setFieldValue('template', selectedItem.pack)
      props.formik.setFieldValue('name', selectedItem.title)
    }
  }, [selectedItem])

  useEffect(() => {
    if (selectedItem !== null) {
      onClickHandler(selectedItem.pack, selectedItem.title)
    }
  }, [selectedItem])

  return <>
    <Table>
      <TableWrapper className={'overflow-auto'}>
        <TableBody>
          {hasData &&
            Array.from({ length: columnsCount }, (_, columnIndex) => (
              <TableColumn key={columnIndex}>
                {templatesNChunks &&
                  templatesNChunks[columnIndex]?.map((row: GenerationTemplate, rowIndex: number) => (
                    <TableColumnWrapper key={rowIndex}>
                      <TableRow>
                        <TableCell>
                          <TableValue className={row?.uploaded ?? ''}>
                            {row?.uploaded !== 'added'
                              ? <>
                                <SIconBlock>
                                  <STooltip title={t('white-generator:Send to Keitaro')}>
                                    <SButtonAction
                                      onClick={() => onClickHandler(row?.pack, row?.title)}
                                      type={'submit'}
                                      disabled={sendGeneratedLoading || props.formik.values.generations?.pack === null}
                                      $buttonstyle={'send'}>
                                      <SendIcon/>
                                    </SButtonAction>
                                  </STooltip>
                                </SIconBlock>
                                {row?.title}
                              </>
                              : <>
                                <SIconBlock>
                                  <STooltip title={t('white-generator:Open Link')}>
                                    <a
                                      href={row?.uploaded_link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <SButtonAction
                                        type={'button'}
                                        onClick={() => {
                                        }}
                                        $buttonstyle={'openLink'}
                                      >
                                        <OpenInNewSharpIcon/>
                                      </SButtonAction>
                                    </a>
                                  </STooltip>
                                </SIconBlock>
                                {row?.title}
                              </>
                            }
                          </TableValue>
                        </TableCell>
                      </TableRow>
                    </TableColumnWrapper>
                  ))}
              </TableColumn>
            ))
          }
          {
            !generateLoading
            && !lastGeneratedLoading
            && !sendGeneratedLoading
            && !generateError
            && (!generations?.pack || generations?.pack.length === 0)
            && (
              <TableAlertMessage>
                <AlertMessage severity="warning">
                  {t('white-generator:Oops! The table is empty - no data!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            !hasData
            && (lastGeneratedLoading || sendGeneratedLoading) && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  {t('white-generator:Just a minute! Table is loading/updating!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
          {
            generateError
            && !lastGeneratedLoading && (
              <TableAlertMessage>
                <AlertMessage severity="error">
                  {t('white-generator:Uh oh! Synchronize failed! Please try again!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  </>
}

const SIconBlock = styled.div`
  margin-right: 10px;
`

const SButtonAction = styled.button<ActionButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 26px;
  width: 26px;
  z-index: 99;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 #AAB7C1;
  transition: 0.3s;
  border-color: transparent;

  ${'svg'} {
    font-size: 19px;
  }

  &:hover {
    color: #FFFFFF;
    ${({ $buttonstyle }) => {
    switch ($buttonstyle) {
    case 'send':
      return css`
            background: #00738E;
          `
    case 'openLink':
      return css`
            background: #109B69;
          `
    }
  }
}
  }

  ${({ $buttonstyle }) => {
    switch ($buttonstyle) {
    case 'send':
      return css`
          background: #189EAE;
          color: #FFFFFF;
        `
    case 'openLink':
      return css`
          background: #25AF43;
          color: #FFFFFF;
        `
    }
  }}
`
