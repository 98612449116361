import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Drawer, List, ListItemIcon, ListItemText, Collapse, Box, ListItemButton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import AddRoadIcon from '@mui/icons-material/AddRoad'
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter'
import RemoveIcon from '@mui/icons-material/Remove'
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import { t } from 'i18next'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useAppStore } from '../../entities/app'
import { GuestLogoIcon } from '../../shared/ui/icon-assets/guest-logo'
import { useDimensionsWindow } from '../../shared/lib'
import { ENVIRONMENT } from '../../shared/configs/enums/common'

const drawerWidth = 300

export function Sidebar() {
  const { info: appInfo, infoLoading: appInfoLoading } = useAppStore()
  const { sidebarOpen, setSidebarOpen } = useAppStore()
  const { screenSize } = useDimensionsWindow()
  const [collapsedNeeded, setCollapsedNeeded] = useState(screenSize.width > 768)
  const storedOpenSubmenu = sessionStorage.getItem('openSubmenu')
  const initialOpenSubmenuState = storedOpenSubmenu ? JSON.parse(storedOpenSubmenu) : {}
  const [openSubmenu, setOpenSubmenu] = useState<{ [key: string]: boolean }>(initialOpenSubmenuState)
  const location = useLocation()
  const navigate = useNavigate()

  const isCustomBreakpoint = useMediaQuery('(max-width:768px)')

  const routeToMenuMap: { [key: string]: string } = {
    '/kacc': 'kacc',
    '/subdomains-generator': 'subdomains-generator',
    // Сюда добавляем новые маршруты и подменю
  }

  // первый маршрут
  const firstRouteInMenuMap: { [key: string]: string } = {
    'kacc': '/kacc',
    'subdomains-generator': '/subdomains-generator',
    // Сюда добавляем новые маршруты и подменю
  }

  const isRouteActive = (route: string) => window.location.pathname === route

  const isRouteWithChildrenActive = (route: string) => {
    const currentPath = window.location.pathname
    return currentPath === route || currentPath.startsWith(`${route}/`)
  }

  const handleSubmenuToggle = (menu: string) => {
    setOpenSubmenu((prevState) => {
      const newState = { ...prevState, [menu]: !prevState[menu] }

      if (!prevState[menu] && newState[menu]) {
        navigate(firstRouteInMenuMap[menu])
      }

      return newState
    })
  }

  useEffect(() => {
    const pathname = location.pathname

    // Закрытие всех подменю при переходе на новый маршрут
    if (!Object.keys(routeToMenuMap).some(route => pathname.startsWith(route))) {
      setOpenSubmenu(prevState => {
        const newState = { ...prevState }
        Object.keys(newState).forEach(key => newState[key] = false)
        return newState
      })
    } else {
      Object.keys(routeToMenuMap).forEach((route) => {
        if (pathname.startsWith(route)) {
          setOpenSubmenu(prevState => ({
            ...prevState,
            [routeToMenuMap[route]]: true,
          }))
        }
      })
    }
  }, [location.pathname])

  useEffect(() => {
    sessionStorage.setItem('openSubmenu', JSON.stringify(openSubmenu))
  }, [openSubmenu])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const drawer = document.querySelector('.MuiDrawer-paper')
      if (drawer && !drawer.contains(event.target as Node)) {
        setOpenSubmenu(prevState => {
          const newState = { ...prevState }
          Object.keys(newState).forEach(key => newState[key] = false)
          return newState
        })
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    if (isCustomBreakpoint) {
      setSidebarOpen(false)
    } else {
      setSidebarOpen(true)
    }
  }, [isCustomBreakpoint])

  useEffect(() => {
    setCollapsedNeeded(screenSize.width > 768)
  }, [screenSize])


  const drawerContent = (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 2 }}>
        <a href="/">
          <GuestLogoIcon design="sidebar"/>
        </a>
      </Box>

      <List>
        <ListItemButton
          component={RouterLink as React.ElementType}
          to="/"
          selected={isRouteActive('/')}
        >
          <ListItemIcon>
            <SpaceDashboardOutlinedIcon/>
          </ListItemIcon>
          <ListItemText primary={t('global:Dashboard')}/>
        </ListItemButton>

        <ListItemButton
          component={RouterLink as React.ElementType}
          to="/domains-watcher"
          selected={isRouteActive('/domains-watcher')}
        >
          <ListItemIcon>
            <PublishedWithChangesIcon/>
          </ListItemIcon>
          <ListItemText primary={t('global:Domains watcher')}/>
        </ListItemButton>

        <ListItemButton
          component={RouterLink as React.ElementType}
          to="/custom-reports"
          selected={isRouteWithChildrenActive('/custom-reports')}
        >
          <ListItemIcon>
            <AddRoadIcon/>
          </ListItemIcon>
          <ListItemText primary={t('global:Custom reports')}/>
        </ListItemButton>

        <ListItemButton
          onClick={() => handleSubmenuToggle('kacc')}
          selected={isRouteWithChildrenActive('/kacc')}
        >
          <ListItemIcon>
            <AnalyticsOutlinedIcon/>
          </ListItemIcon>
          <ListItemText primary="KACC"/>
          {openSubmenu['kacc'] ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse
          in={openSubmenu['kacc']}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={RouterLink as React.ElementType}
              to="/kacc"
              selected={isRouteActive('/kacc')}
            >
              <ListItemIcon>
                <RemoveIcon/>
              </ListItemIcon>
              <ListItemText primary="КАСС catalog"/>
            </ListItemButton>
            <ListItemButton
              component={RouterLink as React.ElementType}
              to="/kacc/integrations"
              selected={isRouteActive('/kacc/integrations')}
            >
              <ListItemIcon>
                <RemoveIcon/>
              </ListItemIcon>
              <ListItemText primary="Integrations"/>
            </ListItemButton>
            <ListItemButton
              component={RouterLink as React.ElementType}
              to="/kacc/campaign-list"
              selected={isRouteActive('/kacc/campaign-list')}
            >
              <ListItemIcon>
                <RemoveIcon/>
              </ListItemIcon>
              <ListItemText primary="Campaign list"/>
            </ListItemButton>
            <ListItemButton
              component={RouterLink as React.ElementType}
              to="/kacc/campaign-changes"
              selected={isRouteActive('/kacc/campaign-changes')}
            >
              <ListItemIcon>
                <RemoveIcon/>
              </ListItemIcon>
              <ListItemText primary="Campaign changes history"/>
            </ListItemButton>
          </List>
        </Collapse>

        {
          !appInfoLoading && appInfo?.environment !== ENVIRONMENT.PRODUCTION && (
            <ListItemButton
              onClick={() => handleSubmenuToggle('subdomains-generator')}
              selected={isRouteWithChildrenActive('/subdomains-generator')}
            >
              <ListItemIcon>
                <PhotoFilterIcon/>
              </ListItemIcon>
              <ListItemText primary={t('global:Subdomains generator')}/>
              {openSubmenu['subdomains-generator'] ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
          )
        }

        {
          !appInfoLoading && appInfo?.environment !== ENVIRONMENT.PRODUCTION && (
            <Collapse
              in={openSubmenu['subdomains-generator']} timeout="auto"
              unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={RouterLink as React.ElementType}
                  to="/subdomains-generator"
                  selected={isRouteActive('/subdomains-generator')}
                >
                  <ListItemIcon>
                    <RemoveIcon/>
                  </ListItemIcon>
                  <ListItemText primary={t('global:Generator')}/>
                </ListItemButton>
                <ListItemButton
                  component={RouterLink as React.ElementType}
                  to="/subdomains-generator/settings"
                  selected={isRouteActive('/subdomains-generator/settings')}
                >
                  <ListItemIcon>
                    <RemoveIcon/>
                  </ListItemIcon>
                  <ListItemText primary={t('global:Settings')}/>
                </ListItemButton>
              </List>
            </Collapse>
          )
        }

        {
          !appInfoLoading && appInfo?.environment !== ENVIRONMENT.PRODUCTION && (
            <ListItemButton
              component={RouterLink as React.ElementType}
              to="/white-generator"
              selected={isRouteActive('/white-generator')}
            >
              <ListItemIcon>
                <AutoFixHighIcon/>
              </ListItemIcon>
              <ListItemText primary={t('global:White page generator')}/>
            </ListItemButton>
          )}

        <ListItemButton
          component={RouterLink as React.ElementType}
          to="/watch-cat"
          selected={isRouteWithChildrenActive('/watch-cat')}
        >
          <ListItemIcon>
            <TravelExploreIcon/>
          </ListItemIcon>
          <ListItemText primary={t('global:Watch Cat')}/>
        </ListItemButton>
      </List>
    </>
  )
  return (
    <Drawer
      variant={isCustomBreakpoint ? 'temporary' : 'permanent'}
      anchor="left"
      open={sidebarOpen}
      onClose={() => setSidebarOpen(false)}
      transitionDuration={0}
      sx={{
        width: collapsedNeeded && !sidebarOpen ? 0 : drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: collapsedNeeded && !sidebarOpen ? 0 : drawerWidth,
          boxSizing: 'border-box',
          borderRight: '1px solid #212529',
          backgroundColor: '#275572',
        },
      }}
    >
      {drawerContent}
    </Drawer>
  )
}
