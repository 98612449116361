import React from 'react'
import { useSnackbar } from 'notistack'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { t } from 'i18next'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Alert, Box, CircularProgress, darken, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { Button } from '../../../../../shared/ui/button'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'
import {
  addIntegration,
  deleteIntegration,
  updateIntegration,
  useIntegrationsStore,
  IntegrationType,
} from '../../../../../entities/settings/integrations-new'
import { AntyDolphinLogo } from '../../../../../shared/ui/icon-assets/anty-dolphin-logo'
import { KeitaroLogo } from '../../../../../shared/ui/icon-assets/keitaro-logo'
import { FlexLogo } from '../../../../../shared/ui/icon-assets/flex-logo'
import { Tooltip } from '../../../../../shared/ui/tooltip'

import type { IntegrationCredentials, IntegrationsList } from '../../../../../entities/settings/integrations-new'
import type { ConfigureIntegrationModalInitial, ConfigureIntegrationModalProps } from '../types'

export const ConfigureIntegrationModal = ({ initialValues, onClose, isOpen }: ConfigureIntegrationModalProps) => {
  const { getIntegrations } = useIntegrationsStore()
  const { enqueueSnackbar } = useSnackbar()

  // Set default credentials keys based on type
  const integrationCredentialsInitial: IntegrationsList['credentials'] | undefined =
      initialValues?.type === IntegrationType.ANTY_DOLPHIN
        ? { token: '' }
        : initialValues?.type === IntegrationType.KEITARO
          ? { url: '', token: '' }
          : initialValues?.type === IntegrationType.FLEX_CARDS
            ? { token: '' }
            : undefined

  // Set form initial values
  const integrationInitialValues: ConfigureIntegrationModalInitial = {
    ...(initialValues?.id && { id: initialValues?.id }),
    type: initialValues?.type,
    credentials: initialValues?.credentials ?? integrationCredentialsInitial,
    title: initialValues?.title ?? '',
    description: initialValues?.description ?? '',
    submit: null,
  }

  // Set form validation schemas
  const integrationValidationSchema = Yup.object().shape({
    title: Yup.string()
      .max(255)
      .required(t('settings:Title field is required')),
    description: Yup.string()
      .max(255, t('settings:Description can be not more then 255 characters'))
      .nullable(),
  })

  // Helper function to capitalize the first letter of a string
  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()

  // Set onSubmit action
  const onSubmitHandler = async (
    values: ConfigureIntegrationModalInitial,
    { setStatus, setSubmitting }: FormikHelpers<ConfigureIntegrationModalInitial>,
  ) => {
    try {
      // Set create or update request
      if (!values?.id) {
        await addIntegration(values)
      } else {
        await updateIntegration(values.id, values)
      }

      // Close modal
      setTimeout(() => onClose?.(), 500)

      // Update integrations list
      await getIntegrations()

      // Show notification success
      if (!initialValues?.id) {
        enqueueSnackbar(
          t('settings:{{name}} was added successfully!', { name: values.title }), { variant: 'success' },
        )
      } else {
        enqueueSnackbar(
          t('settings:{{name}} was edited successfully!', { name: values.title }), { variant: 'success' },
        )
      }

      // Set status success
      setStatus({ success: true })
    } catch (error: any) {
      // Set status unsuccessful
      setStatus({ success: false })

      // Show notification error
      if (!initialValues?.id) {
        enqueueSnackbar(
          t('settings:{{name}} was not added!', { name: values.title }) + ' ' +
          t(`settings:${error.response?.data?.message ?? error?.message}`),
          { variant: 'error' },
        )
      } else {
        enqueueSnackbar(
          t('settings:{{name}} was not edited!', { name: values.title }) + ' ' +
          t(`settings:${error.response?.data?.message ?? error?.message}`),
          { variant: 'error' },
        )
      }
    } finally {
      // Set submit action ended
      setSubmitting(false)
    }
  }

  // Set delete action
  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: ConfigureIntegrationModalInitial['id'],
  ) => {
    e.preventDefault()

    await deleteIntegration(Number(id))

    // Show notification success
    enqueueSnackbar(
      t('settings:{{type}} integration was deleted successfully!', { type: capitalize(String(initialValues?.type)) }),
      { variant: 'success' },
    )

    // Update integrations list
    await getIntegrations()

    // Close modal
    onClose?.()
  }

  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {t('settings:Configure integration')}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>

        <SModalBody>
          <Formik
            initialValues={integrationInitialValues}
            validationSchema={integrationValidationSchema}
            onSubmit={onSubmitHandler}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <SModalForm
                noValidate
                onSubmit={handleSubmit}
              >
                <SModalContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Logo>
                      {initialValues?.type === IntegrationType.ANTY_DOLPHIN && (<AntyDolphinLogo/>)}
                      {initialValues?.type === IntegrationType.KEITARO && (<KeitaroLogo/>)}
                      {initialValues?.type === IntegrationType.FLEX_CARDS && (<FlexLogo/>)}
                    </Logo>

                    <div
                      style={
                        {
                          display: 'flex',
                          marginTop: 'auto',
                          gap: '15px',
                        }
                      }>
                      {initialValues?.id &&
                      <SDeleteButton
                        variant={'contained'}
                        type={'button'}
                        size={'medium'}
                        disabled={isSubmitting}
                        onClick={(e) => handleDelete(e, initialValues?.id)}
                      >
                        {isSubmitting
                          ? <CircularProgress size="1rem"/>
                          : null}
                        {t('settings:Delete')}
                      </SDeleteButton>
                      }
                    </div>
                  </div>

                  <Data>
                    <TextField
                      sx={{
                        marginTop: '0px',
                        marginBottom: '6px',
                        height: 'auto',
                        '.MuiInputBase-root': { padding: '10px 0' },
                        '.MuiInputBase-input': { padding: '0px 14px' },
                      }}
                      multiline={true}
                      minRows={1}
                      maxRows={3}
                      error={Boolean(touched.submit && touched.title && errors.title)}
                      fullWidth
                      margin="normal"
                      autoFocus
                      size={'small'}
                      label={t('settings:Title')}
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.title?.trimStart()}
                      variant="outlined"
                    />

                    <TextField
                      sx={{
                        marginTop: '14px',
                        marginBottom: '6px',
                        height: 'auto',
                        '.MuiInputBase-root': { padding: '10px 0' },
                        '.MuiInputBase-input': { padding: '0px 14px' },
                      }}
                      multiline={true}
                      minRows={1}
                      maxRows={3}
                      error={Boolean(touched.submit && touched.description && errors.description)}
                      fullWidth
                      margin="normal"
                      size={'small'}
                      label={t('settings:Description')}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.description}
                      variant="outlined"
                    />

                    <Credentials>{t('settings:Credentials')}</Credentials>

                    {integrationCredentialsInitial &&
                        Object.keys(integrationCredentialsInitial).map((key) => (
                          <Box
                            key={key}
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              marginTop: '8px',
                            }}
                          > {/* Container for the label and TextField */}
                            <Typography
                              variant="subtitle1"
                              style={{
                                color: '#8c8b8b',
                                flex: '0 0 70px', // Fixed width for labels, can adjust as needed
                              }}
                            >
                              {t(`settings:${capitalize(key)}`)} {/* Label displayed above the TextField */}
                            </Typography>
                            <TextField
                              sx={
                                key === 'token' ? {
                                  height: 'auto',
                                  marginTop: '8px',
                                  '.MuiInputBase-root': { padding: '10px 0' },
                                  '.MuiInputBase-input': { padding: '0px 14px' },
                                } : {
                                  height: '40px',
                                  marginTop: '8px',
                                  '.MuiInputBase-root': { height: '40px' },
                                  '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                                  '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                                  '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                                }}
                              InputProps={
                                initialValues?.type === IntegrationType.KEITARO && key === 'url'
                                  ? { endAdornment: <InputAdornment position="end">
                                    <Tooltip
                                      title="https://example.com https://subdomain.example.com http://127.0.0.1">
                                      <IconButton
                                        size="small"
                                        sx={{ m: 0, p: 0 }}
                                        aria-label="info"
                                      >
                                        <HelpOutlineIcon style={{ fontSize: '14px' }} />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment> }
                                  : {}
                              }
                              multiline={key === 'token'}
                              minRows={1}
                              maxRows={initialValues?.type === IntegrationType.ANTY_DOLPHIN ? 10: 5}
                              value={
                                (values.credentials as IntegrationCredentials)[key as keyof IntegrationCredentials]
                              }
                              onBlur={handleBlur}
                              placeholder={
                                initialValues?.type === IntegrationType.KEITARO && key === 'url'
                                  ? 'https://example.com'
                                  : t('settings:Enter token')
                              }
                              onChange={handleChange}
                              name={`credentials.${key}`}
                              variant="outlined"
                              fullWidth
                              margin="normal"
                            />
                          </Box>
                        ))}

                    <div style={{ marginBottom: '1rem', marginTop: '0.5rem' }}>
                      {touched.submit && Object.keys(errors).length > 0 && (
                        <Alert severity="error">
                          {errors.title && <div> {t(`settings:${errors.title}`)} </div>}
                          {errors.description && <div> {t(`settings:${errors.description}`)} </div>}
                        </Alert>)
                      }
                    </div>

                    <div
                      style={
                        {
                          display: 'flex',
                          marginTop: 'auto',
                          gap: '15px',
                        }
                      }>
                      <div
                        style={
                          {
                            display: 'flex',
                            marginLeft: 'auto',
                            gap: '15px',
                          }
                        }
                      >
                        <Button
                          variant={'contained'}
                          disabled={isSubmitting}
                          type={'submit'}
                          size={'medium'}
                          onClick={() => handleSubmit}
                        >
                          {isSubmitting
                            ? <CircularProgress size="1rem"/>
                            : null}
                          {t('settings:Submit')}
                        </Button>

                        <SModalCancel
                          variant={'outlined'}
                          onClick={onClose}
                          size={'medium'}
                          type={'reset'}
                          style={{ marginLeft: 'auto' }}
                        >
                          {t('settings:Cancel')}
                        </SModalCancel>
                      </div>
                    </div>
                  </Data>
                </SModalContainer>
              </SModalForm>
            )}
          </Formik>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
  display: inline-flex;
  padding: 16px 24px;
  height: 100%;
  width: 600px;
`
const SModalContainer = styled.div`
  display: flex; 
  flex-direction: row;
  gap: 24px;
`
const Data = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const SModalHeader = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary.main};
  display: inline-flex;
  padding: 16px 24px;
  flex-shrink: 0;
  justify-content: space-between;
`
const SModalHeaderText = styled.div`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`
const SModalHeaderClose = styled(CloseIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.alpha.white[100]};

  &.MuiSvgIcon-root {
    font-size: 1rem;
  }
`
const SModalForm = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 280px;
  height: 100%;
  width: 100%;
`
// .attrs({ as: 'a' })
const SModalCancel = styled(Button)`
  &.MuiButtonBase-root {
    border-color: #FF6961;
    color: #FF6961;

    &:hover {
      border-color: ${darken('#FF6961', 0.05)};
      color: ${darken('#FF6961', 0.05)};
    }
  }
`

const SDeleteButton = styled(Button)`
  &.MuiButtonBase-root {
    background-color: #FF6961;
    color: #FFF;

    &:hover {
      background-color: ${darken('#FF6961', 0.1)};
    }
  }
`

const Logo = styled.div`
    border-radius: 4px;
    flex-grow: 0;

    & svg {
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
    }
`

const Credentials = styled.div`
  border-bottom: 1px solid #f3f2f2;
  color: #8c8b8b;
  margin-top: 16px;
  padding: 8px 0;
  text-transform: uppercase;
`
