import { IntegrationsList, IntegrationType } from '../../../../entities/settings/integrations-new'

export const checkCredentials = (type: IntegrationType, integration: IntegrationsList | undefined) => {
  if (type === IntegrationType.ANTY_DOLPHIN) {
    if (!integration?.credentials.hasOwnProperty('token'))
      return false
  }
  if (type === IntegrationType.KEITARO) {
    if (!integration?.credentials.hasOwnProperty('token') || !integration?.credentials.hasOwnProperty('url'))
      return false
  }
  if (type === IntegrationType.FLEX_CARDS) {
    if (!integration?.credentials.hasOwnProperty('token'))
      return false
  }
}
