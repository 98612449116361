import React, { useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'

import { PageContainer } from '../../../../shared/ui/page'
import { CustomChartCampaignFilter } from '../../../../features/custom-reports/info/filter-by-campaign'
import { ResetAllFilters } from '../../../../features/custom-reports/info/reset-all-filters'
import { useCustomReportInfoStore } from '../../../../entities/custom-reports/info'
import { ChartAlertMessage } from '../../../dashboard/ui/alerts'
import { AlertMessage } from '../../../../shared/ui/alert-message'

import { TitleBlock } from './title-block'
import { ReportChart } from './report-chart'
import { ReportChartWithCountry } from './report-chart-with-country'

export function HeaderBlock() {
  const [isChartVisible, setIsChartVisible] = useState(false)
  const { chartReport, reportIsLoading, reportHasError } = useCustomReportInfoStore()

  const toggleChartVisibility = () => {
    setIsChartVisible(!isChartVisible)
  }

  const hasCampaignGroupData = chartReport?.filterList && chartReport.filterList.length > 0

  return (
    <PageContainer>
      {/* Основной заголовок и кнопка */}
      <SHeaderBlock>
        <TitleBlock/>
        <SButtonChart onClick={toggleChartVisibility}>
          {t('View data on graph')}
        </SButtonChart>
      </SHeaderBlock>

      {reportHasError && (
        <ChartAlertMessage>
          <AlertMessage severity="error">
            {t(reportHasError)}
          </AlertMessage>
        </ChartAlertMessage>
      )}

      {!chartReport && (
        <ChartAlertMessage>
          <AlertMessage severity="warning">
            {t('kacc:Oops! The Chart is - no data!')}
          </AlertMessage>
        </ChartAlertMessage>
      )}

      {isChartVisible && !reportIsLoading && (
        <SChartBlock>
          {hasCampaignGroupData ? (
            <>
              <SFilterBlock>
                <CustomChartCampaignFilter/>
                <ResetAllFilters/>
              </SFilterBlock>
              <ReportChart/>
            </>
          ) : (
            <ReportChartWithCountry/>
          )}
        </SChartBlock>
      )}
    </PageContainer>
  )

}

const SHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const SButtonChart = styled.button`
  border: transparent;
  border-radius: 3px;
  padding: 7px 16px;
  box-shadow: 0 1px 1px 0 #aab7c1;
  background: #ddf4ff;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: 0.01em;
  color: #0d79bd;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px;

  &:hover {
    background: #c6e6f5;
  }
`

const SChartBlock = styled.div``

const SFilterBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`