import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { MainLayout } from '../../widgets/layouts'
import { useDashboardStore } from '../../entities/dashboard'
import {
  AnnualProgressChart,
  GroupsCampaignChart,
  WeekProgressBlock,
  CountriesChart,
  NetworksChart,
  GroupsCampaignFilters,
} from '../../widgets/dashboard'

import {
  SPage,
  SPageBlock,
  SInfoBlock,
  STitleChart,
  SPageWithMoreBlocks,
  SChartBlockTwo,
  SChartBlockThree,
} from './style'

function LazyChart({ children }: { children: React.ReactNode }) {
  const [isVisible, setIsVisible] = useState(false)
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.disconnect()
        }
      },
      { threshold: 0.1 },
    )

    if (chartRef.current) {
      observer.observe(chartRef.current!)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return <div ref={chartRef}>{isVisible && children}</div>
}

export function Dashboard() {
  const {
    getWeekProgress,
    getGroupsProgress,
    getAnnualProgress,
    getNetworksList,
    getCountriesList,
  } = useDashboardStore()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    (async () => {
      try{
        await Promise.all([
          getWeekProgress(),
          getGroupsProgress(searchParams),
          getAnnualProgress(),
          getNetworksList(),
          getCountriesList(),
        ])
      }catch (error) {
        console.error(error)
      }
    })()

  }, [])

  useEffect(() => {
    getGroupsProgress(searchParams)
  }, [searchParams])


  return (
    <MainLayout header={t('Dashboard')}>
      <SPage>

        {/*two charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockTwo>
              <LazyChart>
                <WeekProgressBlock/>
              </LazyChart>
            </SChartBlockTwo>
            <SChartBlockTwo>
              <LazyChart>
                <GroupsCampaignFilters/>
              </LazyChart>
              <LazyChart>
                <GroupsCampaignChart/>
              </LazyChart>
            </SChartBlockTwo>
          </SPageWithMoreBlocks>
        </SPageBlock>
        {/*three charts*/}
        <SPageBlock>
          <SPageWithMoreBlocks>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Networks. Top of the week.')}</STitleChart>
                {/*<SIconButtonChart>*/}
                {/*  <MoreHorizIcon/>*/}
                {/*</SIconButtonChart>*/}
              </SInfoBlock>
              <LazyChart>
                <NetworksChart/>
              </LazyChart>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Annual progress')}</STitleChart>
                {/*<SIconButtonChart>*/}
                {/*  <MoreHorizIcon/>*/}
                {/*</SIconButtonChart>*/}
              </SInfoBlock>
              <LazyChart>
                <AnnualProgressChart/>
              </LazyChart>
            </SChartBlockThree>
            <SChartBlockThree>
              <SInfoBlock>
                <STitleChart>{t('Countries. Top of the week.')}</STitleChart>
                {/*<SIconButtonChart>*/}
                {/*  <MoreHorizIcon/>*/}
                {/*</SIconButtonChart>*/}
              </SInfoBlock>
              <LazyChart>
                <CountriesChart/>
              </LazyChart>
            </SChartBlockThree>
          </SPageWithMoreBlocks>
        </SPageBlock>
      </SPage>
    </MainLayout>
  )
}
