import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import debounce from 'debounce'

import { Page, PageBlock, PageContainer } from '../../shared/ui/page'
import { useDomainsWatcherStore } from '../../entities/domains-watcher'
import { FiltersBlock, PaginationBlock, TableBlock } from '../../widgets/domains-watcher'
import { MainLayout } from '../../widgets/layouts'
import { useSettingsIntegrationsStore } from '../../entities/settings/integrations'

export function DomainsWatcher() {
  const [search] = useSearchParams()
  const { listDomains } = useDomainsWatcherStore()
  const { getIntegrations } = useSettingsIntegrationsStore()
  const { t } = useTranslation('domains-watcher')

  const debouncedResults = useMemo(() => {
    return debounce(() => listDomains(search), 300)
  }, [listDomains, search])

  useEffect(() => {
    getIntegrations()
  }, [getIntegrations])

  useEffect(() => {
    debouncedResults()

    return () => {
      debouncedResults.clear()
    }
  }, [debouncedResults])

  return (
    <MainLayout header={t('Domains watcher')}>
      <Page>
        <PageBlock>
          <PageContainer>
            <FiltersBlock/>
            <TableBlock/>
            <PaginationBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
