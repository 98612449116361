import { request } from '../../../shared/api'

import type { AxiosError } from 'axios'
import type { CustomReportInfoResponse, CustomReportChartResponse } from './types'

export const getReport = (id: number, query: URLSearchParams): Promise<CustomReportInfoResponse | AxiosError> =>
  request.get('/custom-reports/report/get', { params: { ...Object.fromEntries(query), id } })

export const postChartReport = (
  id: string | null,
  query: URLSearchParams,
): Promise<CustomReportChartResponse | AxiosError> =>
  request.get('/custom-reports/report/get-chart-data', { params: { ...Object.fromEntries(query), id } })
