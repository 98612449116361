import dayjs from 'dayjs'

// date format: 2023-08-30 11:06:30
export const getPrettyDate = (date: string | null, withTime?: boolean): string | null => {
  if (!date) {
    return date
  }
  const parsedDate = dayjs(date)
  const dateFormat = withTime
    ? 'DD MMM YYYY HH:mm:ss'
    : 'DD MMM YYYY'

  return parsedDate.format(dateFormat)
}
