import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { listDomains, syncDomains } from './api'

import type { DomainsWatcherData } from './types'

export interface IDomainsWatcherStore {
  list: DomainsWatcherData['domains'] | null,
  listCount: DomainsWatcherData['count'] | null,
  listDomains: (search: URLSearchParams) => void,
  listFilters: DomainsWatcherData['filters'] | null,
  listIsLoading: boolean,
  listHasError: string | null,

  syncDomains: (search: URLSearchParams) => void,
  syncDomainsIsLoading: boolean,
  syncDomainsHasError: string | null,
}

export const useDomainsWatcherStore = create<IDomainsWatcherStore>((set, get) => ({
  list: null,
  listCount: null,
  listFilters: null,
  listIsLoading: false,
  listHasError: null,

  listDomains: async (search) => {
    try {
      set({ list: null })
      set({ listCount: null })
      set({ listFilters: null })
      set({ listHasError: null })
      set({ listIsLoading: true })
      set({ syncDomainsIsLoading: false })
      const response = await listDomains(search)
      if (!axios.isAxiosError(response)) {
        set({ list: response.data.domains })
        set({ listCount: response.data.count })
      }
    } catch (error) {
      let errorText = t('domains-watcher:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ listHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ listIsLoading: false })
    }
  },

  syncDomainsIsLoading: false,
  syncDomainsHasError: null,
  syncDomains: async (search) => {
    try {
      set({ syncDomainsIsLoading: true })
      set({ syncDomainsHasError: null })

      const response = await syncDomains()
      if (!axios.isAxiosError(response)) {
        get().listDomains(search)
      }
    } catch (error) {
      let errorText = t('domains-watcher:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ syncDomainsHasError: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ syncDomainsIsLoading: false })
    }
  },
}))
