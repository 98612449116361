import { AxiosError, AxiosResponse } from 'axios'

import { request } from '../../shared/api'

import { DomainsWatcherDomain, DomainsWatcherDomainResponse, WhoisApiDomainResponse } from './types'

export const listDomains = (params: URLSearchParams): Promise<DomainsWatcherDomainResponse | AxiosError> =>
  request.get('/domains-watcher/list', { params })

export const addDomain = (
  domain: DomainsWatcherDomain['domain'],
  comment?: DomainsWatcherDomain['comment'],
): Promise<AxiosResponse | AxiosError> =>
  request.post('/domains-watcher/add', { domain, comment })

export const editDomain = (
  id: DomainsWatcherDomain['id'],
  comment?: DomainsWatcherDomain['comment'],
): Promise<AxiosResponse | AxiosError> =>
  request.put(`/domains-watcher/edit/${id}`,
    { comment })

export const deleteDomain = (id: DomainsWatcherDomain['id']): Promise<AxiosResponse | AxiosError> =>
  request.delete(`/domains-watcher/delete/${id}`, {})

export const syncDomains = async (): Promise<AxiosResponse | AxiosError> =>
  request.get('/domains-watcher/sync')

export const syncDomain = async (id: DomainsWatcherDomain['id']): Promise<AxiosResponse | AxiosError> =>
  request.get(`/domains-watcher/sync/${id}`)

export const whoisDomain = async (params: URLSearchParams): Promise<WhoisApiDomainResponse | AxiosError> =>
  request.get('/domains-watcher/whois', { params })
