import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { getPrettyDate, SortColumnParam, SortTypeParam, useDimensions, useSort } from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { TableButtonAction } from '../../../../shared/ui/table-button-action'
import { TableRowStatus } from '../../../../shared/ui/table-row-status'
import { OfferListSortKeys, useWatchCatCatalogStore } from '../../../../entities/watch-cat/catalog'
import { SortOrder } from '../../../../shared/configs/enums/pagination'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableDataMenu,
  TableValue,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
  SGoalsMore,
  SGoalsMoreButton,
  SOfferListDataGeo,
  STooltip,
  SReactCountryFlag,
} from './table'

export function TableBlock() {
  const { list, loading, error } = useWatchCatCatalogStore()
  const [search, setSearch] = useSearchParams()
  const { sortActive, sortColumn } = useSort()

  // data
  const dataOffers = list || null
  const dataOffersError = error ?? null
  const hasData = (
    !loading && !dataOffersError && dataOffers && dataOffers?.length > 0
  )

  // table dimensions
  const offersHeaderRef = useRef(null)
  const offersHeader = useDimensions(offersHeaderRef)
  const minWidth = offersHeader.offsetWidth
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      offersHeader.offsetHeight + offersHeader.offsetTop + 95
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      offersHeader.offsetTop + 95
    }px)`
    : ''

  useEffect(() => {
    // if a search query has no sortColumn
    // with a value in dataColumns.sortKey
    // remove search queries: column, sort
    if (sortColumn && !(sortColumn in OfferListSortKeys)) {
      search.delete(SortColumnParam)
      search.delete(SortTypeParam)
      setSearch(search, { replace: true })
    }
  }, [sortColumn])

  const getSortDirectionClassName = (
    column: string,
    initialSort: SortOrder = SortOrder.ASC,
    initialColumn: OfferListSortKeys = OfferListSortKeys.createdAt,
  ) => {
    let dir = ''

    if (column === sortColumn) {
      if (sortActive === initialSort)
        dir = initialSort
      else
        dir = initialSort !== SortOrder.ASC
          ? SortOrder.ASC
          : SortOrder.DESC
    }

    if (!sortActive || !sortColumn)
      dir = column === initialColumn
        ? initialSort
        : dir

    return dir
  }

  return (
    <Table>
      <TableWrapper
        className="overflow-auto"
        style={{ height: maxHeight }}
      >
        {hasData && (
          <TableHeader>
            <TableRow ref={offersHeaderRef}>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Status')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Offer name')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Network')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Goals')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Geos')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Geo')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Price')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Payment')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Currency')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel
                  className={getSortDirectionClassName(OfferListSortKeys.createdAt)}
                >
                  {t('watch-cat:Date created')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Date updated')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('watch-cat:Menu')}
                </TableHeaderLabel>
              </TableCell>
            </TableRow>
          </TableHeader>
        )}
        <TableBody style={{ height: minHeight }}>
          {hasData && dataOffers
                        && dataOffers.map((row, index) => (
                          <div
                            key={index + 1}
                            style={{ minWidth: minWidth }}
                          >
                            <TableRow>
                              <TableCell>
                                <TableValue>
                                  <TableRowStatus
                                    active={row.active}
                                    activeText={t('watch-cat:Active')}
                                    inactiveText={t('watch-cat:Inactive')}
                                  />
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {row?.name}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {row?.network}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {Object.values(row?.goal)?.length}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {Object.values(row?.geo)?.length}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {/*{ Geo }*/}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {/*{ Price }*/}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {/*{ PPL }*/}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {/*{ Currency }*/}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {getPrettyDate(row?.date_created, true)}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  {getPrettyDate(row?.date_updated, true)}
                                </TableValue>
                              </TableCell>
                              <TableCell>
                                <TableValue>
                                  <TableDataMenu>
                                    <STooltip className={'watch'} title={t('watch-cat:Watch')}>
                                      <a
                                        href={`/watch-cat/offer/${row.id_generated}`}
                                        rel="noreferrer"
                                      >
                                        <TableButtonAction
                                          onClick={() => {
                                          }} target={'watch'}/>
                                      </a>
                                    </STooltip>
                                    <STooltip className={'link'} title={t('watch-cat:Link')}>
                                      <a
                                        href={row.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <TableButtonAction
                                          onClick={() => {
                                          }} target={'link'}/>
                                      </a>
                                    </STooltip>
                                  </TableDataMenu>
                                </TableValue>
                              </TableCell>
                            </TableRow>

                            {Object.values(row.goal).length > 0
                                    && Object.values(row.goal).slice(0, 4)
                                      .map((goal, index) => (
                                        <TableRow className={'goal'} key={index + 1}>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Active }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Name }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Network }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Goals }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Geos }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {goal.geo
                                                            && <SOfferListDataGeo>
                                                              <SReactCountryFlag
                                                                countryCode={String(goal.geo)}
                                                                svg
                                                                style={{
                                                                  height: '12px',
                                                                  width: 'auto',
                                                                }}
                                                              />
                                                              {String(goal.geo)}
                                                            </SOfferListDataGeo>}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {goal.price ?? ''}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {goal.cash ?? ''}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {goal.currency ?? ''}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Created }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Updated }*/}
                                            </TableValue>
                                          </TableCell>
                                          <TableCell>
                                            <TableValue>
                                              {/*{ Menu }*/}
                                            </TableValue>
                                          </TableCell>
                                        </TableRow>
                                      ))
                            }

                            {
                              Object.values(row.goal).length > 4
                                    && (
                                      <TableRow className={'goal centered'}>
                                        <SGoalsMore>
                                          <SGoalsMoreButton
                                            href={`/watch-cat/offer/${row.id_generated}`}
                                            rel="noreferrer"
                                          >
                                            {t('watch-cat:View more')
                                                        + ' +'
                                                        + (Object.values(row.goal).length - 4)}
                                          </SGoalsMoreButton>
                                        </SGoalsMore>
                                      </TableRow>
                                    )
                            }
                          </div>
                        ))
          }
          {
            !dataOffersError
                        && !loading
                        && dataOffers?.length === 0 &&
                        (
                          <TableAlertMessage>
                            <AlertMessage severity="warning">
                              {t('watch-cat:Oops! The table is empty - no data!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }
          {!hasData && dataOffersError && (
            <TableAlertMessage>
              <AlertMessage severity="error">
                {t(`watch-cat:${dataOffersError}`)}
              </AlertMessage>
            </TableAlertMessage>
          )}
          {
            !hasData
                        && loading &&
                        (
                          <TableAlertMessage>
                            <AlertMessage severity="info">
                              {t('watch-cat:Just a minute! Table is loading/updating!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
