import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { useCustomReportInfoStore } from '../../../../entities/custom-reports/info'

export const ReportChartWithCountry = () => {
  const { chartReport, reportIsLoading, reportHasError } = useCustomReportInfoStore()
  const customCountryChartRef = useRef<ReactApexChart | null>(null)
  const customCountryContainerRef = useRef<HTMLDivElement | null>(null)

  const profitConfirmedData = chartReport?.data?.map(item => item.profitConfirmed ?? 0) || []
  const countriesList = chartReport?.data?.map(item => item.country || '') || []

  const series = [
    {
      data: profitConfirmedData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Отключаем зум
      },
      selection: {
        enabled: false, // Отключаем выделения
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    dataLabels: {
      style: {
        fontSize: '10px',
      },
      formatter: function (value: number) {
        return `${value.toFixed(2)} $`
      },
    },
    plotOptions: {
      bar: {
        horizontal: true ,
        dataLabels: {
          position: 'top',
          hideOverflowingLabels: false,
        },
        barHeight: '100%',
      },
    },
    xaxis: {
      categories: countriesList,
      labels: {
        formatter: (val: any) => {
          return val / 1000 + 'K'
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value: number) {
          return `${value.toFixed(2)} $`
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#337AFF', '#33FF57', '#FF5733'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
  }

  return (
    <ChartContainer ref={customCountryContainerRef}>
      <ReactApexChart
        ref={customCountryChartRef}
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`