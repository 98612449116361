import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import { t } from 'i18next'

import { ModalOverlayMui } from '../../../../shared/ui/modal-overlay-mui'

import { DomainInfoCard } from './domain-info-card'

import type { AddEditDomainModalProps } from '../types'

export const DomainInfoPopup = ({ domain, onClose, isOpen }: AddEditDomainModalProps) => {
  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {t('domains-watcher:{{domain}} info', { domain: domain.domain })}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>
        <SModalBody>
          <DomainInfoCard domain={domain}/>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
    min-height: 300px;
    max-height: 80vh;
    height: 100%;
    overflow: auto;
    width: 500px;
`
const SModalHeader = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.primary.main};
    display: inline-flex;
    padding: 16px 24px;
    flex-shrink: 0;
    justify-content: space-between;
`
const SModalHeaderText = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    max-width: 430px;
    overflow-wrap: break-word;
    overflow: hidden;
`
const SModalHeaderClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha.white[100]};

    &.MuiSvgIcon-root {
        font-size: 1rem;
    }
`
