import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { PaginationOffsetParam, usePagination, useFilter } from '../../../shared/lib'
import { DomainsWatcherExpireRange } from '../../../entities/domains-watcher'

export const ExpireFilterParam = 'expire_date'

/**
 * ExpiredFilter feature
 * @constructor {JSX.Element}
 */
export function ExpiredFilter() {
  const { filterActive } = useFilter(ExpireFilterParam, '')
  const { offsetActive } = usePagination()
  const [search, setSearch] = useSearchParams()
  const filters = Object.values(DomainsWatcherExpireRange)

  /**
     * Handle onChange action
     */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(ExpireFilterParam, value as string)
    if (!value && filterActive) search.delete(ExpireFilterParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check expired query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && !Object.values(DomainsWatcherExpireRange).includes(filterActive as DomainsWatcherExpireRange)
      )) {
        search.delete(ExpireFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive ? t(`domains-watcher:${filterActive}`) : null}
      defaultValue={filterActive ? t(`domains-watcher:${filterActive}`) : null}
      onChange={handleOnChange}
      options={filters ?? []}
      openOnFocus={true}
      noOptionsText={t('domains-watcher:No options')}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => t(`domains-watcher:${option}`) === value}
      renderOption={(props, option) => (
        (option !== '' && <Box
          component="li"
          value={option}
          {...props}
        >
          {t(`domains-watcher:${option}`)}
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('domains-watcher:Expiration term')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
