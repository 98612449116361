import axios from 'axios'
import { create } from 'zustand'
import { t } from 'i18next'

import { getIntegrationsList } from './api'

import type { DataError, IntegrationsList } from './types'

export interface IIntegrationsStore {
    loaded: boolean,
    loading: boolean,
    error: DataError | null,
    list: IntegrationsList[] | null,
    getIntegrations: () => Promise<void>,
}

export const useIntegrationsStore = create<IIntegrationsStore>((set, get) => ({
  loaded: false,
  loading: false,
  error: null,
  list: null,
  getIntegrations: async () => {
    try {
      set({ list: null })
      set({ error: null })
      set({ loaded: false })
      set({ loading: true })

      const response = await getIntegrationsList()
      if (!axios.isAxiosError(response)) {
        set({ list: response.data })
      }
    } catch (error) {
      let errorText = t('settings:Unknown error')

      if (axios.isAxiosError(error)) {
        set({ error: error.response?.data.message || errorText })
        errorText = error.response?.data.message
      }

      throw errorText
    } finally {
      set({ loaded: true })
      set({ loading: false })
    }
  },
}))
