import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { getPrettyDate, SortColumnParam, SortTypeParam, useDimensions, useSort } from '../../../../shared/lib'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { TableButtonAction } from '../../../../shared/ui/table-button-action'
import { TableRowStatus } from '../../../../shared/ui/table-row-status'
import { useCustomReportsCatalogStore, CustomReportsSortEnum } from '../../../../entities/custom-reports/catalog'
import { EditReportButton } from '../../../../features/custom-reports/catalog/add-edit-report'
import { SortOrder } from '../../../../shared/configs/enums/pagination'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableDataMenu,
  TableValue,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
  STooltip,
  SGroupName,
} from './table'

export function TableBlock() {
  const { catalog, catalogHasError, catalogIsLoading } = useCustomReportsCatalogStore()
  const { setAsc, setDesc, sortActive, sortColumn, toggleSort } = useSort()
  const [search, setSearch] = useSearchParams()

  // data
  const hasData = (
    !catalogIsLoading && !catalogHasError && catalog && catalog?.length > 0
  )

  // table dimensions
  const offersHeaderRef = useRef(null)
  const offersHeader = useDimensions(offersHeaderRef)
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      offersHeader.offsetHeight + offersHeader.offsetTop + 95
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      offersHeader.offsetTop + 95
    }px)`
    : ''

  const getSortDirectionClassName = (
    column: string,
    initialSort: SortOrder = SortOrder.DESC,
    initialColumn: CustomReportsSortEnum = CustomReportsSortEnum.created_at,
  ) => {
    let dir = ''

    if (column === sortColumn) {
      if (sortActive === initialSort)
        dir = initialSort
      else
        dir = initialSort !== SortOrder.DESC
          ? SortOrder.ASC
          : SortOrder.DESC
    }

    if (!sortActive || !sortColumn)
      dir = column === initialColumn
        ? initialSort
        : dir

    return dir
  }

  const handleSortChange = (
    column: string,
    initialSort: SortOrder = SortOrder.DESC,
    initialColumn: CustomReportsSortEnum = CustomReportsSortEnum.created_at,
  ) => {
    if (!sortActive && !sortColumn) {
      column === initialColumn && initialSort !== SortOrder.ASC
        ? setAsc(column)
        : setDesc(column)
    } else if (sortColumn && sortColumn !== column) {
      initialSort === SortOrder.ASC
        ? setAsc(column)
        : setDesc(column)
    } else {
      toggleSort(column)
    }
  }

  useEffect(() => {
    // if a search query has no sortColumn
    // with a value in dataColumns.sortKey
    // remove search queries: column, sort
    if (sortColumn && !(sortColumn in CustomReportsSortEnum)) {
      search.delete(SortColumnParam)
      search.delete(SortTypeParam)
      setSearch(search, { replace: true })
    }
  }, [sortColumn])


  return (
    <Table>
      <TableWrapper className="overflow-auto" style={{ height: maxHeight }}>
        {hasData && (
          <TableHeader ref={offersHeaderRef}>
            <TableRow>
              <TableCell>
                <TableHeaderLabel
                  className={getSortDirectionClassName(CustomReportsSortEnum.id)}
                  onClick={() => handleSortChange(CustomReportsSortEnum.id)}
                >
                  {t('custom-reports:Id')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel
                  className={getSortDirectionClassName(CustomReportsSortEnum.name)}
                  onClick={() => handleSortChange(CustomReportsSortEnum.name)}
                >
                  {t('custom-reports:Name')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel
                  className={getSortDirectionClassName(CustomReportsSortEnum.comment)}
                  onClick={() => handleSortChange(CustomReportsSortEnum.comment)}
                >
                  {t('custom-reports:Comment')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('custom-reports:Status')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('custom-reports:Groups')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel
                  className={getSortDirectionClassName(CustomReportsSortEnum.updated_at)}
                  onClick={() => handleSortChange(CustomReportsSortEnum.updated_at)}>
                  {t('custom-reports:Date updated')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel
                  className={getSortDirectionClassName(CustomReportsSortEnum.created_at)}
                  onClick={() => handleSortChange(CustomReportsSortEnum.created_at)}>
                  {t('custom-reports:Date created')}
                </TableHeaderLabel>
              </TableCell>
              <TableCell>
                <TableHeaderLabel className={'disabled'}>
                  {t('custom-reports:Menu')}
                </TableHeaderLabel>
              </TableCell>
            </TableRow>
          </TableHeader>
        )}

        <TableBody style={{ height: minHeight }}>
          {hasData && catalog
                        && catalog?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <TableValue>
                                {row?.id}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {row?.name}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {row?.comment}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                <TableRowStatus
                                  active={!row.disabled}
                                  activeText={t('custom-reports:Active')}
                                  inactiveText={t('custom-reports:Inactive')}
                                />
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {row.groups?.filter(group => !group.disabled)
                                  ?.map((group, groupIndex) => (
                                    <SGroupName key={groupIndex}>
                                      {group?.name}
                                    </SGroupName>
                                  ))}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {getPrettyDate(row?.updated_at, true)}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                {getPrettyDate(row?.created_at, true)}
                              </TableValue>
                            </TableCell>
                            <TableCell>
                              <TableValue>
                                <TableDataMenu>
                                  <EditReportButton initialValues={row}/>
                                  <STooltip className={'watch'} title={t('custom-reports:Watch')}>
                                    <a
                                      href={`/custom-reports/info/${row.id}`}
                                      rel="noreferrer"
                                    >
                                      <TableButtonAction
                                        onClick={() => {
                                        }}
                                        target={'watch'}
                                      />
                                    </a>
                                  </STooltip>
                                  <STooltip className={'link'} title={t('custom-reports:Link')}>
                                    <a
                                      href={row.url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <TableButtonAction
                                        onClick={() => {
                                        }}
                                        target={'link'}
                                        disabled={!Boolean(row?.url?.length)}
                                      />
                                    </a>
                                  </STooltip>
                                </TableDataMenu>
                              </TableValue>
                            </TableCell>
                          </TableRow>
                        ))
          }

          {!hasData && !catalogIsLoading && !catalogHasError
                        && (
                          <TableAlertMessage>
                            <AlertMessage severity="warning">
                              {t('custom-reports:Oops! The table is empty - no data!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )}
          {!hasData && catalogHasError
                        && (
                          <TableAlertMessage>
                            <AlertMessage severity="error">
                              {t(`custom-reports:${catalogHasError}`)}
                            </AlertMessage>
                          </TableAlertMessage>
                        )}
          {!hasData && catalogIsLoading
                        && (
                          <TableAlertMessage>
                            <AlertMessage severity="info">
                              {t('custom-reports:Just a minute! Table is loading/updating!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )}
        </TableBody>
      </TableWrapper>
    </Table>
  )
}
