import React, { useState } from 'react'
import { t } from 'i18next'

import { Button } from '../../../../shared/ui/button'

import { WhoisDomainPopup } from './whois-domain-popup'

export const WhoisDomainButton = () => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <WhoisDomainPopup
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
      />

      <Button
        onClick={() => setModalVisible(true)}
        variant={'contained'}
        size={'small'}
        type={'button'}
      >
        {t('domains-watcher:Whois')}
      </Button>
    </>
  )
}
