import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Autocomplete, Box, TextField } from '@mui/material'

import { useCustomReportInfoStore } from '../../../../entities/custom-reports/info'
import { useFilter } from '../../../../shared/lib'

export const ChartCampaignFilterParam = 'campaign_group'

export function CustomChartCampaignFilter() {
  const { chartReport } = useCustomReportInfoStore()
  const { filterActive } = useFilter(ChartCampaignFilterParam, '')
  const [search, setSearch] = useSearchParams()

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: string | null) => {
    if (value) search.set(ChartCampaignFilterParam, value)
    if (!value && filterActive) search.delete(ChartCampaignFilterParam)
    setSearch(search, { replace: true })
  }

  // Check query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (
        filterActive && chartReport?.filterList && (
          !(chartReport.filterList.some(e => e.campaignGroup === filterActive))
        )
      )) {
        search.delete(ChartCampaignFilterParam)
        setSearch(search, { replace: true })
      }
    }
  }, [chartReport, filterActive])

  // Get options as strings
  const options = chartReport?.filterList?.map((item) => item.campaignGroup) ?? []

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive ?? null}
      defaultValue={filterActive ?? null}
      onChange={handleOnChange}
      options={options}
      openOnFocus={true}
      noOptionsText={'No options'}
      getOptionLabel={(option: string) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option: string) => (
        option !== '' && (
          <li {...props}>
            <Box>
              {option}
            </Box>
          </li>
        )
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={'Campaign'}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
