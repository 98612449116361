import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'

import { SyncButton } from '../../../shared/ui/sync-button'
import { useDomainsWatcherStore } from '../../../entities/domains-watcher'
import { Tooltip } from '../../../shared/ui/tooltip'

export const SyncDomains = () => {
  const { syncDomains, syncDomainsIsLoading } = useDomainsWatcherStore()
  const [search] = useSearchParams()

  const updateHandler = () => syncDomains(search)

  return (
    <Tooltip title={t('domains-watcher:Sync Keitaro')}>
      <SyncButton
        onClick={updateHandler}
        updating={syncDomainsIsLoading}
      >
        {t('domains-watcher:Sync')}
      </SyncButton>
    </Tooltip>
  )
}
