import { request } from '../../../shared/api'

import { IntegrationsListResponse, IntegrationHistoryResponse } from './types'

import type { AxiosError, AxiosResponse } from 'axios'

export const getList = (params: URLSearchParams): Promise<IntegrationsListResponse | AxiosError> =>
  request.get('/integrations/list', { params })

export const editFBMarketingApiRequest = (
  antyId: number,
  newValue: string,
  oldValue: string | null,
): Promise<AxiosResponse | AxiosError> =>
  request.post('/integrations/change-mapi', { antyId, newValue, oldValue })

export const integrateAntyAccounts = (values: number[] | null): Promise<AxiosResponse | AxiosError> =>
  request.post('/integrations/integrate', { ids: values })

export const syncFacebook = (): Promise<AxiosResponse | AxiosError> =>
  request.get('/kacc/kacc-mapi-sync', {})

export const syncWithAntyAndKeitaro = (): Promise<AxiosResponse | AxiosError> =>
  request.get('/kacc/sync', {})

export const getIntegrationsHistory = (): Promise<IntegrationHistoryResponse | AxiosError> =>
  request.get('/integrations/history', {})
