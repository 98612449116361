import React from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { Topbar } from '../topbar'
import { Sidebar } from '../sidebar'
import { usePageTitle } from '../../shared/lib/usePageTitle'

export type MainLayoutProps = {
    header: string,
    children?: React.ReactNode;
};

const StyledMainLayout = styled.div`
    display: flex;
    height: 100%;
`

const StyledContentWrapper = styled.div`
    width: 100%;
`

export function MainLayout({ header, children }: MainLayoutProps) {
  usePageTitle(header)

  return (
    <StyledMainLayout>
      <Helmet>
        <title>{header}</title>
      </Helmet>
      <Sidebar/>
      <StyledContentWrapper>
        <Topbar/>
        {children}
      </StyledContentWrapper>

    </StyledMainLayout>
  )
}
