import React, { useMemo, useRef } from 'react'
import { ApexOptions } from 'apexcharts'
import ReactApexChart from 'react-apexcharts'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'
import { AlertMessage } from '../../../shared/ui/alert-message'

import { ChartAlertMessage } from './alerts'

export const GroupsCampaignChart = () => {
  const { groupProgress, groupIsLoading, groupHasError } = useDashboardStore()
  const [search] = useSearchParams()
  const campaignChartRef = useRef<ReactApexChart | null>(null)
  const campaignContainerRef = useRef<HTMLDivElement | null>(null)

  const { sortedGroupsProgress, series, options } = useMemo(() => {
    if (groupIsLoading || groupHasError || !groupProgress) {
      return { sortedGroupsProgress: [], series: [], options: {} }
    }

    const interval = search.get('interval')

    let sortedGroupsProgress = [...groupProgress.data]

    if (interval === 'yesterday') {
      sortedGroupsProgress = sortedGroupsProgress.sort((a, b) => {
        const timeA = a.reportTime ? new Date(`1970-01-01T${a.reportTime}:00Z`).getTime() : 0
        const timeB = b.reportTime ? new Date(`1970-01-01T${b.reportTime}:00Z`).getTime() : 0
        return timeA - timeB
      })
    } else {
      sortedGroupsProgress = sortedGroupsProgress.sort((a, b) => {
        return new Date(a.reportDate).getTime() - new Date(b.reportDate).getTime()
      })
    }

    const labels = sortedGroupsProgress.map(item =>
      (interval === 'yesterday' ? item.reportTime ?? '00:00' : item.reportDate),
    )

    const series = [
      {
        name: 'Revenue',
        data: sortedGroupsProgress.map(item => item.saleRevenue),
      },
      {
        name: 'Cost',
        data: sortedGroupsProgress.map(item => item.cost),
      },
      {
        name: 'Profit',
        data: sortedGroupsProgress.map(item => item.profitConfirmed),
      },
    ]

    const options: ApexOptions = {
      chart: {
        id: 'groups-campaign-chart',
        type: 'line',
        height: 350,
        width: '100%',
        redrawOnParentResize: true,
        redrawOnWindowResize: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#337AFF', '#FF5733', '#33FF57'],
      xaxis: {
        categories: labels,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(0)
          },
        },
        title: {
          text: 'Values',
        },
      },
      stroke: {
        curve: 'smooth',
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== 'undefined') {
              return y.toFixed(2) + ' $ '
            }
            return y
          },
        },
      },
    }

    return { sortedGroupsProgress, series, options }
  }, [groupProgress, groupIsLoading, groupHasError, search])

  if (groupIsLoading) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="info">
          {t('kacc:Just a minute! Chart is loading/updating!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (groupHasError) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="error">
          {t(groupHasError)}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  if (!sortedGroupsProgress || sortedGroupsProgress.length === 0) {
    return (
      <ChartAlertMessage>
        <AlertMessage severity="warning">
          {t('kacc:Oops! The Chart is - no data!')}
        </AlertMessage>
      </ChartAlertMessage>
    )
  }

  return (
    <ChartContainer ref={campaignContainerRef} style={{ marginTop: '25%' }}>
      <ReactApexChart
        ref={campaignChartRef}
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
