import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Autocomplete, Box, TextField } from '@mui/material'
import styled from 'styled-components'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'

import { useFilter } from '../../../../shared/lib'
import { KaccCatalogIntervalsEnum, useKaccCatalogStore } from '../../../../entities/kacc/catalog'

import type { KaccCatalogInterval } from '../../../../entities/kacc/catalog'

export const IntervalFilterParam = 'interval_id'

/**
 * SourceFilter feature
 */
export function IntervalFilter() {
  const { filterActive } = useFilter(IntervalFilterParam, '')
  const [search, setSearch] = useSearchParams()
  const { intervals } = useKaccCatalogStore()
  const initialOption = intervals?.find((item) => item.id === Number(filterActive))
    ?? intervals?.find((item) => item.interval === KaccCatalogIntervalsEnum.SEVEN_DAYS_AGO)

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: KaccCatalogInterval | null) => {
    if (value) search.set(IntervalFilterParam, String(value.id))
    if (!value && filterActive) search.delete(IntervalFilterParam)
    setSearch(search, { replace: true })
  }

  // Check query values
  // Check query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if(intervals){
        if (!initialOption || (initialOption && !(initialOption.id === Number(filterActive)))) {
          search.delete(IntervalFilterParam)
          setSearch(search, { replace: true })
        }
      }
    }

  }, [intervals, initialOption, filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={initialOption ?? null}
      defaultValue={initialOption ?? null}
      onChange={handleOnChange}
      options={intervals ?? []}
      openOnFocus={true}
      noOptionsText={t('kacc:No options')}
      getOptionLabel={(option) => t(`kacc:${option.interval}`)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        (option && <Box
          {...props}
          key={option?.id}
          component="li"
          style={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          value={option.id}
        >
          {t(`kacc:${option.interval}`)}
          {
            (option.interval === KaccCatalogIntervalsEnum.CUSTOM_DATE_RANGE
                || option.interval === KaccCatalogIntervalsEnum.CUSTOM_TIME_RANGE) &&
              (
                <SLabelDateTime>
                  ({option?.interval_from} – {option?.interval_to})
                </SLabelDateTime>
              )
          }
        </Box>)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('kacc:Select interval')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
      style={{
        width: 320,
      }}
    />
  )
}

const SLabelDateTime = styled.div`
    color: #b2b7c1;
`
