import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import * as React from 'react'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Checkbox, FormControlLabel } from '@mui/material'

import { Button } from '../../../../../shared/ui/button'
import { ModalOverlayMui } from '../../../../../shared/ui/modal-overlay-mui'
import {
  SModalHeader,
  SModalBody,
  SModalContent,
  SMessageWrapper,
  SIconWrapper,
  SMessageTitle,
  SMessageDescription,
  SModalButtonWrapper,
  SModalCancel,
} from '../style'
import { useKaccAntyListStore } from '../../../../../entities/kacc/integration'

import type { CheckModalProps } from '../types'

export const CheckModal = ({ initialValues, onClose, isOpen }: CheckModalProps) => {
  const [syncFacebookValue, setSyncFacebookValue] = useState(initialValues.syncFacebook)
  const { syncKeitaro } = useKaccAntyListStore()
  const [search] = useSearchParams()

  const handleClick = async () => {
    try {
      setTimeout(() => onClose?.(), 500)
      await syncKeitaro(search, syncFacebookValue)

      if (!syncFacebookValue) {
        enqueueSnackbar(
          t('kacc:Sync all was success!'), { variant: 'success' },
        )
      } else {
        enqueueSnackbar(
          t('kacc:Sync all with the Facebook was success!'), { variant: 'success' },
        )
      }
    } catch (error) {
      enqueueSnackbar(`${t(String(error))}`, { variant: 'error' })
    } finally {
      setSyncFacebookValue(false)
    }
  }

  return (
    <ModalOverlayMui isOpen={isOpen}>
      <SModalHeader></SModalHeader>
      <SModalBody>
        <SModalContent style={{ padding: '1rem' }}>
          <SIconWrapper>
            <ErrorOutlineOutlinedIcon fontSize={'inherit'} color={'inherit'}/>
          </SIconWrapper>
          <SMessageWrapper>
            <SMessageTitle>{t('kacc:Sync Anty Dolphin and Keitaro including Facebook?')}</SMessageTitle>
            <SMessageDescription>
              {t('kacc:It is recommended to sync with Facebook no more than once every 8 hours!')}
              {' '}
              {t('kacc:You can manually sync with Facebook by selecting the checkbox below')}
            </SMessageDescription>
            <SMessageDescription>
              <FormControlLabel
                control={<Checkbox
                  checked={syncFacebookValue}
                  onClick={() => setSyncFacebookValue(!syncFacebookValue)}/>}
                label={t('kacc:Include Facebook synchronization')}/>
            </SMessageDescription>
          </SMessageWrapper>
        </SModalContent>
        <SModalButtonWrapper>
          <Button
            variant="contained"
            onClick={handleClick}
            size={'medium'}
            type={'button'}
            style={{ marginLeft: 'auto' }}
            disableRipple
          >
            {t('kacc:Sync')}
          </Button>
          <SModalCancel
            variant={'outlined'}
            onClick={onClose}
            size={'medium'}
            type={'reset'}
            style={{ marginLeft: 'auto' }}
          >
            {t('kacc:Cancel')}
          </SModalCancel>
        </SModalButtonWrapper>
      </SModalBody>
    </ModalOverlayMui>
  )
}