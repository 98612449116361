import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Extend dayjs with the customParseFormat plugin
dayjs.extend(customParseFormat)

export function getFormattedDateStamp(dateStr: string, disableTime: boolean = false) {
  // Try parsing common formats with dayjs
  const parsedDate = dayjs(dateStr, [
    'YYYY-MM-DDTHH:mm:ss.SSSZ', // ISO 8601 format
    'YYYY-MM-DD HH:mm:ss', // Standard datetime format
    'DD-MMM-YYYY', // Custom format like 13-Aug-2024
  ])

  // Check if the parsed date is valid
  if (!parsedDate.isValid()) {
    return dateStr
  }

  // Return formatted date, omitting time if not present in the input
  return disableTime
    ? parsedDate.format('DD-MM-YYYY') // Date only
    : parsedDate.format('DD-MM-YYYY, HH:mm:ss') // Include time
}
