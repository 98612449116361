import styled from 'styled-components'

import { AddDomainButton } from '../../../features/domains-watcher/add-edit-domain'
import { ExpiredFilter } from '../../../features/domains-watcher/filter-expired'
import { SourceFilter } from '../../../features/domains-watcher/filter-source'
import { ResetAllFilters } from '../../../features/domains-watcher/reset-all-filters'
import { DomainSearchFilter } from '../../../features/domains-watcher/search-domains'
import { SyncDomains } from '../../../features/domains-watcher/sync-domains'
import { WhoisDomainButton } from '../../../features/domains-watcher/whois-domain'

export function FiltersBlock() {
  return (
    <SFiltersBlock>
      <SFiltersGroup>
        <SyncDomains/>
        <AddDomainButton/>
        <WhoisDomainButton/>
        <DomainSearchFilter/>
      </SFiltersGroup>
      <SFiltersGroup>
        <SourceFilter/>
        <ExpiredFilter/>
        <ResetAllFilters/>
      </SFiltersGroup>
    </SFiltersBlock>
  )
}

const SFiltersBlock = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 16px;
`

const SFiltersGroup = styled.div`
    display: flex;
    gap: 15px;
`
