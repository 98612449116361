import React, { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Page, PageBlock, PageContainer } from '../../../shared/ui/page'
import { useCustomReportInfoStore } from '../../../entities/custom-reports/info'
import {
  FiltersBlock, HeaderBlock,
  PaginationBlock,
  TableBlock,
} from '../../../widgets/custom-reports/info'
import { MainLayout } from '../../../widgets/layouts'

export function CustomReportsInfo() {
  const params = useParams()
  const [search] = useSearchParams()
  const { getCatalog, getChartReport } = useCustomReportInfoStore()
  const { t } = useTranslation('custom-reports')

  useEffect(() => {
    getCatalog(Number(params.reportId), search)
  }, [getCatalog, params.reportId])

  useEffect(() => {
    getChartReport(String(params.reportId), search)
  }, [getChartReport, params.reportId, search])

  return (
    <MainLayout header={t('Custom report info')}>
      <Page>
        <PageBlock>
          <HeaderBlock/>
        </PageBlock>
        <PageBlock>
          <PageContainer>
            <FiltersBlock/>
            <TableBlock/>
            <PaginationBlock/>
          </PageContainer>
        </PageBlock>
      </Page>
    </MainLayout>
  )
}
