import { t } from 'i18next'

import { handleValueEmptyData, setPriceColor } from '../lib'
import { SETTINGS_FORMAT_KEYS, SETTINGS_PRICE_COLOR_KEYS } from '../../../../entities/kacc/catalog'
import { Tooltip } from '../../../../shared/ui/tooltip'

import { TableCell, TableValue } from './table'

import type {
  KaccCatalogAnty,
  KaccCatalogCampaigns,
  KaccCatalogFacebook,
  KaccCatalogOffer,
  KaccCatalogSettingsFilter,
} from '../../../../entities/kacc/catalog'


export type TableBlockBodyGroupsProps = {
    settings: KaccCatalogSettingsFilter,
    settingsKey: keyof KaccCatalogSettingsFilter,
    dataItem: KaccCatalogAnty | KaccCatalogFacebook | KaccCatalogCampaigns | KaccCatalogOffer
    offerIdTooltip?: React.ReactNode,
}

export function TableBlockBodyGroups({ settings, settingsKey, dataItem, offerIdTooltip }: TableBlockBodyGroupsProps) {

  // Type guard to check if the object is of type KaccCatalogFacebook
  function isKaccCatalogFacebook(
    item: KaccCatalogAnty | KaccCatalogFacebook | KaccCatalogCampaigns | KaccCatalogOffer,
  ): item is KaccCatalogFacebook {
    return (item as KaccCatalogFacebook).card_info !== undefined
  }

  return (
    <>
      {settings?.[settingsKey]?.filter((f) => f.checked)
        ?.map((setting, index) => {
          const key = setting.key as keyof TableBlockBodyGroupsProps['dataItem']
          const color = SETTINGS_PRICE_COLOR_KEYS
            .some(value => value === key)
          const format = SETTINGS_FORMAT_KEYS
            .filter(value => value.key === key)
            .map(value => value.format)[0]
                        ?? 'string'

          return key in dataItem && (
            <TableCell
              key={setting.key}
              className={setting.key}
              style={{
                minWidth: setting.width,
                maxWidth: setting.width,
              }}
            >
              {offerIdTooltip && key === 'offer_id' && (
                <Tooltip
                  title={offerIdTooltip} componentsProps={{
                    tooltip: {
                      sx: {
                        color: '#32383E',
                        bgcolor: '#edf4f6',
                        border: '1px solid rgba(38, 86, 115, 0.1)',
                        '& .MuiTooltip-arrow': {
                          color: '#edf4f6',
                        },
                        textAlign: 'left',
                      },
                    },
                  }}>
                  <TableValue
                    style={color ? setPriceColor(Number(dataItem[key])) : {}}>
                    {handleValueEmptyData(dataItem[key], format)}
                  </TableValue>
                </Tooltip>
              )
              }

              {key === 'card_balance' && (
                <>
                  {isKaccCatalogFacebook(dataItem) && dataItem.card_info ? (
                    <Tooltip
                      title={
                        <div style={{ textAlign: 'left' }}>
                          <strong>{t('kacc:card_number')}</strong>{': '}
                          {dataItem.card_info.card_number ?? t('kacc:no_card_info')}<br/>
                          <strong>{t('kacc:card_name')}</strong>{': '}
                          {dataItem.card_info.card_name ?? t('kacc:no_card_info')}<br/>
                          <strong>{t('kacc:daily_limit')}</strong>{': '}
                          {dataItem.card_info.card_daily_limit ?? t('kacc:no_limit')}<br/>
                          <strong>{t('kacc:daily_limit_used')}</strong>{': '}
                          {dataItem.card_info.card_daily_limit_used ?? '0'}<br/>
                          <strong>{t('kacc:monthly_limit')}</strong>{': '}
                          {dataItem.card_info.card_monthly_limit ?? t('kacc:no_limit')}<br/>
                          <strong>{t('kacc:monthly_limit_used')}</strong>{': '}
                          {dataItem.card_info.card_monthly_limit_used ?? '0'}<br/>
                          <strong>{t('kacc:bin_name')}</strong>{': '}
                          {dataItem.card_info.bin_name ?? t('kacc:no_bin')}<br/>
                          <strong>{t('kacc:bin_balance')}</strong>{': '}
                          {dataItem.card_info.bin_balance ?? '0'}
                        </div>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            color: '#32383E',
                            bgcolor: '#edf4f6',
                            border: '1px solid rgba(38, 86, 115, 0.1)',
                            '& .MuiTooltip-arrow': {
                              color: '#edf4f6',
                            },
                          },
                        },
                      }}>
                      <TableValue
                        style={color ? setPriceColor(Number(dataItem[key])) : {}}>
                        {handleValueEmptyData(dataItem[key], format)}
                      </TableValue>
                    </Tooltip>
                  ) : (
                    <TableValue
                      style={color ? setPriceColor(Number(dataItem[key])) : {}}>
                      {handleValueEmptyData(dataItem[key], format)}
                    </TableValue>
                  )
                  }
                </>
              )
              }

              {key !== 'offer_id' && key !== 'card_balance' && (
                <TableValue
                  style={color ? setPriceColor(Number(dataItem[key])) : {}}>
                  {handleValueEmptyData(dataItem[key], format)}
                </TableValue>
              )
              }
            </TableCell>
          )
        })
      }
    </>
  )
}
