import { PageLimit, SortOrder } from '../../../shared/configs/enums/pagination'

import type { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export enum CustomReportsDisabledEnum {
    true = 'true',
    false = 'false',
}

export enum CustomReportsSortEnum {
    id = 'id',
    name = 'name',
    url = 'url',
    comment = 'comment',
    created_at = 'created_at',
    updated_at = 'updated_at',
}

export type CustomReportsGroups = {
    id: number,
    name: string,
    disabled: boolean,
    created_at: string,
    updated_at: string,
}

export type CustomReportsCatalog = {
    id: number,
    name: string,
    comment: string,
    url: string,
    payload: string,
    disabled: boolean,
    created_at: string,
    updated_at: string,
    groups?: CustomReportsGroups[],
}

export type CustomReportsCatalogFilters = {
    offset: number,
    limit: PageLimit,
    sort: CustomReportsSortEnum,
    direction: SortOrder,
    name?: string,
    groups?: string[],
    created_at?: string,
    updated_at?: string,
}

export type CustomReportsData = {
    count: number,
    filters: CustomReportsCatalogFilters,
    reports: CustomReportsCatalog[],
}

export type CustomReportsResponse = {
    data: CustomReportsData
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}

export type CustomReportsGroupsResponse = {
    data: CustomReportsGroups[]
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}

export type CustomReportsAddEditBody = Partial<CustomReportsCatalog> & {
    submit?: null
}

// Make all keys optional
export type CustomReportsManageGroupsBody = Partial<CustomReportsGroups>;
