import React from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { useDashboardStore } from '../../../entities/dashboard'

export function WeekProgressData(){
  const { weekProgress } = useDashboardStore()

  return(
    <STableWrap>
      <STable>
        <SHeaderRow>
          <SHeadItemColumn>{t('Date')}</SHeadItemColumn>
          <SHeadItemColumn>{t('Revenue')}</SHeadItemColumn>
          <SHeadItemColumn>{t('Cost')}</SHeadItemColumn>
          <SHeadItemColumn>{t('Profit')}</SHeadItemColumn>
        </SHeaderRow>
        {weekProgress?.map((progress, index) => (
          <SItem key={index}>
            <SItemColumnDate>{progress.reportDate}</SItemColumnDate>
            <SItemColumn $isCurrency>{(progress.saleRevenue ?? 0).toFixed(2)}</SItemColumn>
            <SItemColumn $isCurrency>{(progress.cost ?? 0).toFixed(2)}</SItemColumn>
            <SItemColumn $isCurrency>{(progress.profitConfirmed ?? 0).toFixed(2)}</SItemColumn>
          </SItem>
        ))}
      </STable>
    </STableWrap>
  )
}

const STableWrap = styled.div`
  display: flex;
  justify-content: center;
`

const STable = styled.div`
  display: block;
  width: 100%;
  min-width: 300px;
`
const SHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  color: #878A99;
  background-color: #E3F2F6;
  padding-top: 5px;
`

const SItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;
  font-size: 12px;
`

const SItemColumn = styled.div<{ $isCurrency?: boolean }>`
  flex: 1;
  border-bottom: 1px solid #ccc;
  text-align: center;
  ${({ $isCurrency }) => $isCurrency && `
    &::after {
      content: ' $';
    }
  `}
`

const SHeadItemColumn = styled(SItemColumn)`
  border-bottom: none;
`

const SItemColumnDate = styled(SItemColumn)`
  font-weight: 500;
`
