import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { t } from 'i18next'
import { Autocomplete, Box, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { PaginationOffsetParam, useFilter, usePagination } from '../../../../shared/lib'

import { IntegrationsStateFilterEnum } from './types'

const IntegrationsStateFilterLabels = {
  [IntegrationsStateFilterEnum.withIntegration]: 'withIntegration',
  [IntegrationsStateFilterEnum.withoutIntegration]: 'withoutIntegration',
}

export const IntegrationStateParam = 'integration'

export function IntegrationStateFilter() {
  const { filterActive } = useFilter(IntegrationStateParam, '')
  const [search, setSearch] = useSearchParams()
  const { offsetActive } = usePagination()
  const filters = Object.values(IntegrationsStateFilterEnum)

  /**
   * Handle onChange action
   */
  const handleOnChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    if (value) search.set(IntegrationStateParam, String(value))
    if (!value && filterActive) search.delete(IntegrationStateParam)
    setSearch(search, { replace: true })

    if (search.get(PaginationOffsetParam) !== null) {
      if (offsetActive && Number(offsetActive) > 0) {
        search.delete(PaginationOffsetParam)
        setSearch(search, { replace: true })
      }
    }
  }

  // Check source query values
  useEffect(() => {
    if (filterActive !== null || filterActive === '') {
      if (!filterActive || (filterActive && filters.indexOf(filterActive as IntegrationsStateFilterEnum) === -1)) {
        search.delete(IntegrationStateParam)
        setSearch(search, { replace: true })
      }
    }
  }, [filterActive])

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<KeyboardArrowDownIcon/>}
      value={filterActive
        ? t(`kacc:${IntegrationsStateFilterLabels[filterActive as IntegrationsStateFilterEnum]}`)
        : null
      }
      defaultValue={filterActive
        ? t(`kacc:${IntegrationsStateFilterLabels[filterActive as IntegrationsStateFilterEnum]}`)
        : null
      }
      onChange={handleOnChange}
      options={filters}
      openOnFocus={true}
      noOptionsText={t('kacc:No options')}
      getOptionLabel={(option) => option}
      isOptionEqualToValue={(option, value) => option === value}
      renderOption={(props, option) => (
        option !== '' && (
          <Box
            {...props}
            key={option}
            component="li"
            value={option}>
            {t(`kacc:${IntegrationsStateFilterLabels[option as IntegrationsStateFilterEnum]}`)}
          </Box>
        )
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('kacc:Integration state')}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        clearIndicator: {
          tabIndex: 0,
        },
      }}
    />
  )
}
