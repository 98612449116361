import React, { useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { SyncButton } from '../../../../../shared/ui/sync-button'
import { Tooltip } from '../../../../../shared/ui/tooltip'
import { useKaccAntyListStore } from '../../../../../entities/kacc/integration'

import { CheckModal } from './check-modal'

export const CheckButton = () => {
  const { syncing } = useKaccAntyListStore()
  const [modalVisible, setModalVisible] = useState(false)

  const initialValues = {
    syncFacebook: false,
  }

  return (
    <>
      <CheckModal
        onClose={() => setModalVisible(false)}
        initialValues={initialValues}
        isOpen={modalVisible}
      />

      <Tooltip title={t('kacc:Sync Anty Dolphin and Keitaro including Facebook?')}>
        <SSyncButtonWrapper>
          <SyncButton
            updating={syncing}
            onClick={() => setModalVisible(true)}
          >
            {t('kacc:Sync')}
          </SyncButton>
        </SSyncButtonWrapper>
      </Tooltip>
    </>
  )
}

const SSyncButtonWrapper = styled.div`
    margin-left: auto;
`
