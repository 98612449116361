import React, { useState } from 'react'
import { t } from 'i18next'
import { useSearchParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import axios, { AxiosResponse } from 'axios'

import { Tooltip } from '../../../../shared/ui/tooltip'
import { downloadCatalog } from '../../../../entities/kacc/catalog'
import { Button } from '../../../../shared/ui/button'

export const DownloadButton: React.FC = () => {
  const [search] = useSearchParams()
  const [isDownloading, setIsDownloading] = useState(false)

  const downloadHandler = async () => {
    setIsDownloading(true)
    try {
      const response: AxiosResponse<Blob> | Error = await downloadCatalog(search)

      if (!axios.isAxiosError(response)) {
        const url = window.URL.createObjectURL(response.data)
        const a = document.createElement('a')
        a.href = url

        const contentDisposition = response.headers['content-disposition']
        a.download = contentDisposition?.split('filename=')[1]?.replace(/"/g, '') || 'catalog.json'
        document.body.appendChild(a)
        a.click()

        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      let errorText = t('kacc:Unknown error')

      if (axios.isAxiosError(error)) {
        errorText = error.response?.data.message
      }

      enqueueSnackbar(errorText, { variant: 'error' })
    } finally {
      setIsDownloading(false)
    }
  }

  return (
    <Tooltip title={t('kacc:Download all data in .json')}>
      <Button
        onClick={downloadHandler}
        disabled={isDownloading}
        variant={'contained'}
        size={'small'}
        type={'button'}
      >
        <div>{t('kacc:Download')}</div>
      </Button>
    </Tooltip>
  )
}
