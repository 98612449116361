import React, { useRef } from 'react'
import styled from 'styled-components'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

import { useCustomReportInfoStore } from '../../../../entities/custom-reports/info'

export const ReportChart = () => {
  const { chartReport } = useCustomReportInfoStore()
  const reportChartRef = useRef<ReactApexChart | null>(null)
  const chartContainerRef = useRef<HTMLDivElement | null>(null)

  const profitData = chartReport?.data.map(item => item.profitConfirmed ?? 0) || []
  const labels = chartReport?.data.map(item => {
    const date = new Date(item.reportDate)
    return date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      // year: '2-digit'
    })
  })

  const series = [
    {
      name: 'Profit Data',
      data: profitData,
    },
  ]

  const options: ApexOptions = {
    chart: {
      id: 'groups-campaign-chart',
      type: 'line',
      height: 350,
      width: '100%',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Отключаем зум
      },
      selection: {
        enabled: false, // Отключаем выделения
      },
    },
    colors: ['#337AFF', '#FF5733', '#33FF57'],
    xaxis: {
      categories: labels,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(0)
        },
      },
      title: {
        text: 'Values',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'right',
      fontSize: '14px',
      itemMargin: {
        horizontal: 15,
        vertical: 0,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(2) + ' $ '
          }
          return y
        },
      },
    },
  }

  return (
    <ChartContainer ref={chartContainerRef}>
      <ReactApexChart
        ref={reportChartRef}
        options={options}
        series={series}
        type="line"
        height={300}
      />
    </ChartContainer>
  )
}

const ChartContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`
