import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Container, styled } from '@mui/material'

import { usePageTitle } from '../../shared/lib/usePageTitle'

const Content = styled(Box)(() => ({
  '&': {
    display: 'flex',
    flex: 1,
    width: '100%',
  },
}),
)

const MainContent = styled(Box)(() => ({
  '&': {
    alignItems: 'center',
    display: 'flex',
    padding: '0 0 0 0',
    width: '100%',
  },
}),
)

const MainContainer = styled(Container)(() => ({
  '&': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5rem',
    zIndex: 2,
  },
}),
)

export type AuthLayoutProps = {
    header: string,
    children?: React.ReactNode;
};

export function ErrorLayout({ header, children }: AuthLayoutProps) {
  usePageTitle(header)

  return (
    <>
      <Helmet>
        <title>{header}</title>
      </Helmet>
      <Content>
        <MainContent>
          <MainContainer maxWidth="sm">
            {children}
          </MainContainer>
        </MainContent>
      </Content>
    </>
  )
}
