import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

export type DataError = {
    error: string | undefined,
    message: string | undefined,
    statusCode: number | undefined,
}

export enum IntegrationType {
    KEITARO = 'KEITARO',
    ANTY_DOLPHIN = 'ANTY_DOLPHIN',
    FLEX_CARDS = 'FLEX_CARDS',
}

export type IntegrationCredentials =
    | IntegrationCredentialsAntyDolphin
    | IntegrationCredentialsKeitaro
    | IntegrationCredentialsFlexCards;

export type IntegrationCredentialsAntyDolphin = {
    token: string;
};

export type IntegrationCredentialsKeitaro = {
    url: string;
    token: string;
};

export type IntegrationCredentialsFlexCards = {
    token: string;
};

export type IntegrationsList = {
    id: number
    active: boolean
    info: Record<string, string | boolean | number>
    type: IntegrationType
    credentials: IntegrationCredentials
    title: string
    description: string
    created_at: Date
    updated_at: Date
}

export type IntegrationsListResponse = {
    data: IntegrationsList[]
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}
