import { AxiosError, type AxiosResponse } from 'axios'

import { request } from '../../../shared/api'

import { IntegrationsList, IntegrationsListResponse } from './types'

import type { KaccCatalogInterval } from '../../kacc/catalog'

export const getIntegrationsList = (): Promise<IntegrationsListResponse | AxiosError> =>
  request.get('/integrations/list')

export const addIntegration = (data: Partial<IntegrationsList>): Promise<AxiosResponse | AxiosError> =>
  request.post('/integrations/add', data)

export const updateIntegration = async (
  id: KaccCatalogInterval['id'],
  data: Partial<IntegrationsList>,
): Promise<AxiosResponse | AxiosError> => request.post(`/integrations/update/${id}`, data)

export const deleteIntegration = async (id: KaccCatalogInterval['id']): Promise<AxiosResponse | AxiosError> =>
  request.delete(`/integrations/delete/${id}`)
