import React, { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import debounce from 'debounce'
import { t } from 'i18next'
import styled from 'styled-components'

import {
  getFormattedFullTime,
  getFormattedRelativeDate,
  SortColumnParam,
  SortTypeParam,
  useDimensions,
  useSort,
} from '../../../shared/lib'
import { AlertMessage } from '../../../shared/ui/alert-message'
import { TableMenu } from '../../../shared/ui/table-menu'
import {
  useDomainsWatcherStore,
  DomainsWatcherSortColumn,
  DomainsWatcherSources,
} from '../../../entities/domains-watcher'
import { EditDomainButton } from '../../../features/domains-watcher/add-edit-domain'
import { DeleteDomainButton } from '../../../features/domains-watcher/delete-domain'
import { DomainInfoButton } from '../../../features/domains-watcher/domain-info'
import { SyncDomainButton } from '../../../features/domains-watcher/sync-domain'
import { useSettingsIntegrationsStore } from '../../../entities/settings/integrations'
import { SortOrder } from '../../../shared/configs/enums/pagination'

import {
  Table,
  TableAlertMessage,
  TableBody,
  TableCell,
  TableValue,
  TableHeader,
  TableHeaderLabel,
  TableRow,
  TableWrapper,
} from './table'

export function TableBlock() {
  const { list, listIsLoading, listHasError, syncDomainsHasError } = useDomainsWatcherStore()
  const { keitaro } = useSettingsIntegrationsStore()
  const { setAsc, setDesc, sortActive, sortColumn, toggleSort } = useSort()

  const [search, setSearch] = useSearchParams()

  // data
  const hasData = (
    !listIsLoading && !listHasError && list && list?.length > 0
  )


  // table dimensions
  const domainsHeaderRef = useRef(null)
  const domainsHeader = useDimensions(domainsHeaderRef)
  const minWidth = domainsHeader.offsetWidth
  const minHeight = hasData
    ? `calc(100vh - ${
      // set datatable body height = maximum screen page
      domainsHeader.offsetHeight + domainsHeader.offsetTop + 95
    }px)`
    : ''
  const maxHeight = hasData
    ? `calc(100vh - ${
      // set datatable height = maximum screen page
      // used to ignore scroll-x
      domainsHeader.offsetTop + 95
    }px)`
    : ''

  // check sort
  useEffect(() => {
    if (sortColumn && !Object.values(DomainsWatcherSortColumn).includes(sortColumn as DomainsWatcherSortColumn)) {
      search.delete(SortColumnParam)
      search.delete(SortTypeParam)
      setSearch(search, { replace: true })
    }
  }, [sortColumn])

  const handleSortChange = (
    column: string,
    initialSort: SortOrder = SortOrder.ASC,
    initialColumn: DomainsWatcherSortColumn = DomainsWatcherSortColumn.EXPIRE_DATE,
  ) => {
    if (!sortActive && !sortColumn) {
      column === initialColumn && initialSort !== SortOrder.ASC
        ? setAsc(column)
        : setDesc(column)
    } else if (sortColumn && sortColumn !== column) {
      initialSort === SortOrder.ASC
        ? setAsc(column)
        : setDesc(column)
    } else {
      toggleSort(column)
    }
  }

  const getSortDirectionClassName = (
    column: string,
    initialSort: SortOrder = SortOrder.ASC,
    initialColumn: DomainsWatcherSortColumn = DomainsWatcherSortColumn.EXPIRE_DATE,
  ) => {
    let direction = ''

    if (column === sortColumn) {
      if (sortActive === initialSort)
        direction = initialSort
      else
        direction = initialSort !== SortOrder.ASC
          ? SortOrder.ASC
          : SortOrder.DESC
    }

    if (!sortActive || !sortColumn)
      direction = column === initialColumn
        ? initialSort
        : direction

    return direction
  }

  const getFormattedDate = (stamp: string) => {
    return `${getFormattedRelativeDate(stamp)}, ${getFormattedFullTime(stamp)}`
  }

  const debouncedSortChange = debounce(handleSortChange, 150)

  return (
    <Table>
      <TableWrapper
        style={{ height: maxHeight }}
        className="overflow-auto"
      >
        {hasData &&
                    (<TableHeader>
                      <TableRow ref={domainsHeaderRef}>
                        <TableCell>
                          <TableHeaderLabel
                            className={getSortDirectionClassName(DomainsWatcherSortColumn.DOMAIN)}
                            onClick={() => debouncedSortChange(DomainsWatcherSortColumn.DOMAIN)}
                          >
                            {t('domains-watcher:Domain')}
                          </TableHeaderLabel>
                        </TableCell>
                        <TableCell>
                          <TableHeaderLabel
                            className={getSortDirectionClassName(DomainsWatcherSortColumn.SOURCE)}
                            onClick={() => debouncedSortChange(DomainsWatcherSortColumn.SOURCE)}
                          >
                            {t('domains-watcher:Source')}
                          </TableHeaderLabel>
                        </TableCell>
                        <TableCell>
                          <TableHeaderLabel
                            className={getSortDirectionClassName(DomainsWatcherSortColumn.SUB_DOMAINS_COUNT)}
                            onClick={() => debouncedSortChange(DomainsWatcherSortColumn.SUB_DOMAINS_COUNT)}
                          >
                            {t('domains-watcher:Subdomains')}
                          </TableHeaderLabel>
                        </TableCell>
                        <TableCell>
                          <TableHeaderLabel
                            className={getSortDirectionClassName(DomainsWatcherSortColumn.EXPIRE_DATE)}
                            onClick={() => debouncedSortChange(DomainsWatcherSortColumn.EXPIRE_DATE)}
                          >
                            {t('domains-watcher:Expiration date')}
                          </TableHeaderLabel>
                        </TableCell>
                        <TableCell>
                          <TableHeaderLabel className={'disabled'}>
                            {t('domains-watcher:Comment')}
                          </TableHeaderLabel>
                        </TableCell>
                        <TableCell>
                          <TableHeaderLabel className={'disabled'}>
                            {t('domains-watcher:Menu')}
                          </TableHeaderLabel>
                        </TableCell>
                      </TableRow>
                    </TableHeader>)
        }
        <TableBody style={{ height: minHeight }}>
          {hasData && list.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              style={{ minWidth: minWidth }}
            >
              <TableCell>
                <TableValue>
                  <DomainInfoButton domain={row}/>
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  {t(`domains-watcher:${String(row.source).toLowerCase()}`)}
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  {
                    row.source === DomainsWatcherSources.MANUAL
                      ? row.sub_domains_count
                      : (
                        <SKeitaroDomainLink
                          href={`http://${
                            keitaro?.url}/admin/#!/domains?s=~(filters~(~(name~'name~expression~'${
                            row.domain}~operator~'CONTAINS)))`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {row.sub_domains_count}
                        </SKeitaroDomainLink>
                      )
                  }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  {(row.expire_date) && getFormattedDate(row.expire_date)}
                  {(!row.expire_date && !row.last_check) &&
                                        (<div>
                                          {t('domains-watcher:Not synced')}
                                        </div>)
                  }
                  {(!row.expire_date && row.last_check) &&
                                        (<div>
                                          {t('domains-watcher:No info')}
                                        </div>)
                  }
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  {row.comment}
                </TableValue>
              </TableCell>
              <TableCell>
                <TableValue>
                  <TableMenu>
                    <EditDomainButton initialValues={row}/>
                    <SyncDomainButton initialValues={row}/>
                    {(row.source === DomainsWatcherSources.MANUAL
                                                || row.source === DomainsWatcherSources.KEITARO_DELETED)
                                            && (
                                              <>
                                                <DeleteDomainButton initialValues={row}/>
                                              </>
                                            )
                    }
                  </TableMenu>
                </TableValue>
              </TableCell>
            </TableRow>
          ))
          }
          {
            !hasData && !listIsLoading && !listHasError && !syncDomainsHasError
                        && (
                          <TableAlertMessage>
                            <AlertMessage severity="warning">
                              {t('domains-watcher:Oops! The table is empty - no data!')}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }
          {
            !hasData && !listHasError && syncDomainsHasError
                        && (
                          <TableAlertMessage>
                            <AlertMessage severity="error">
                              {t(`domains-watcher:${syncDomainsHasError}`)}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }
          {
            !hasData && !syncDomainsHasError && listHasError
                        && (
                          <TableAlertMessage>
                            <AlertMessage severity="error">
                              {t(`domains-watcher:${listHasError}`)}
                            </AlertMessage>
                          </TableAlertMessage>
                        )
          }
          {
            !hasData && listIsLoading && (
              <TableAlertMessage>
                <AlertMessage severity="info">
                  {t('domains-watcher:Just a minute! Table is loading/updating!')}
                </AlertMessage>
              </TableAlertMessage>
            )
          }
        </TableBody>
      </TableWrapper>
    </Table>
  )
}

const SKeitaroDomainLink = styled.a`
    color: #007fa6;
    cursor: pointer;
    width: 500px;
`
