import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
import { t } from 'i18next'
import { TextField } from '@mui/material'
import axios from 'axios'

import { ModalOverlayMui } from '../../../../shared/ui/modal-overlay-mui'
import { Button } from '../../../../shared/ui/button'
import { whoisDomain, WhoisApiDomainInfo } from '../../../../entities/domains-watcher'
import { AlertMessage } from '../../../../shared/ui/alert-message'
import { UTableAlertMessage } from '../../../../shared/ui/table'

import { WhoisDomainCard } from './whois-domain-card'

import type { WhoisDomainModalProps } from '../types'

export const WhoisDomainPopup = ({ onClose, isOpen }: WhoisDomainModalProps) => {
  const [whoisDomainValue, setWhoisDomainValue] = useState<string>('')
  const [whoisDomainInfo, setWhoisDomainInfo] = useState<WhoisApiDomainInfo | null>(null)
  const [whoisDomainError, setWhoisDomainError] = useState<string | null>(null)

  const handleAddSubmit = async () => {
    if (whoisDomainValue) {
      setWhoisDomainValue('')
      setWhoisDomainInfo(null)
      setWhoisDomainError(null)

      try {
        const params = new URLSearchParams()
        params.append('domain', whoisDomainValue)

        const whois = await whoisDomain(params)
        if (!axios.isAxiosError(whois)) {
          setWhoisDomainInfo(whois.data)
        }
      } catch (error: any) {
        setWhoisDomainError(error?.response?.data?.message ?? error?.message)
      } finally {
        setWhoisDomainValue('')
      }
    }
  }

  return (
    <>
      <ModalOverlayMui
        isOpen={isOpen}
        handleClose={onClose}
      >
        <SModalHeader>
          <SModalHeaderText>
            {t('domains-watcher:Whois')}
          </SModalHeaderText>
          <SModalHeaderClose
            onClick={onClose}
          />
        </SModalHeader>
        <SModalBody>
          <SModalForm>
            <div style={{ display: 'flex', alignItems: 'stretch', gap: '12px', margin: '1rem' }}>
              <TextField
                sx={{
                  height: '40px',
                  '.MuiInputBase-root': { height: '40px', alignItems: 'center' },
                  '.MuiInputBase-input': { height: '40px', padding: '0px 14px' },
                  '.MuiOutlinedInput-notchedOutline': { minHeight: '40px' },
                  '.MuiOutlinedInput-root': { height: '40px', alignItems: 'center' },
                }}
                size={'small'}
                style={{ minWidth: '150px', flexGrow: 1 }}
                value={whoisDomainValue?.trimStart() ?? ''}
                label={t('domains-watcher:Domain name')}
                onChange={(e) => setWhoisDomainValue(e.target.value)}
              />
              <Button
                size={'medium'}
                variant="contained"
                color="primary"
                disabled={(!whoisDomainValue || whoisDomainValue.trimStart() === '')}
                onClick={handleAddSubmit}
              >
                {t('domains-watcher:Whois')}
              </Button>
            </div>
            {!whoisDomainError && whoisDomainInfo && (
              <><WhoisDomainCard whois={whoisDomainInfo}/></>
            )}
            {whoisDomainError && (
              <SAlertMessage>
                <AlertMessage severity="error">
                  {t(`domains-watcher:${whoisDomainError}`)}
                </AlertMessage>
              </SAlertMessage>
            )}
          </SModalForm>
        </SModalBody>
      </ModalOverlayMui>
    </>
  )
}

const SModalBody = styled.div`
    min-height: 300px;
    max-height: 80vh;
    height: 100%;
    overflow: auto;
    width: 500px;
`
const SModalHeader = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.primary.main};
    display: inline-flex;
    padding: 16px 24px;
    flex-shrink: 0;
    justify-content: space-between;
`
const SModalHeaderText = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    max-width: 430px;
    overflow-wrap: break-word;
    overflow: hidden;
`
const SModalForm = styled.form`
    display: flex;
    flex-direction: column;
    min-height: 280px;
    height: 100%;
    width: 100%;
`
const SModalHeaderClose = styled(CloseIcon)`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.alpha.white[100]};

    &.MuiSvgIcon-root {
        font-size: 1rem;
    }
`
const SAlertMessage = styled(UTableAlertMessage)`
    & .MuiAlert-message {
        max-width: 390px;
        overflow-wrap: break-word;
        overflow: hidden;
    }
`
