import { Suspense, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'

import { FallbackLoader } from '../shared/ui/fallback-loader'
import { useAppStore } from '../entities/app'

import { PagesWithRoutes, UnderConstructionRoutes } from './routes'
import { withProviders } from './providers'

import '@fontsource/rubik/400.css'
import '@fontsource/rubik/500.css'

const App = () => {
  const { getAppInfo, infoLoading, info } = useAppStore()

  useEffect(() => {
    getAppInfo()
  }, [])

  useEffect(() => {
    if (info) {
      console.log('App version ', info?.version)
    }
  }, [info])

  if (infoLoading) {
    return <FallbackLoader/>
  }

  return (
    <Suspense fallback={ <FallbackLoader/> }>
      <CssBaseline/>
      {
        info?.version
          ? <PagesWithRoutes/>
          : <UnderConstructionRoutes/>
      }
    </Suspense>
  )
}

export default withProviders(App)
