import { AxiosResponseHeaders, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'

import { PageLimit, SortOrder } from '../../shared/configs/enums/pagination'

// Open Api specification: https://admin-api.docs.keitaro.io/#tag/Domains
export interface KeitaroApiDomain {
    id: number;
    name: string;
    network_status: string;
    default_campaign: string;
    default_campaign_id: number;
    state: KeitaroApiDomainState;
    created_at: string;
    updated_at: string;
    catch_not_found: boolean;
    campaigns_count: number;
    ssl_redirect: boolean;
    allow_indexing: boolean;
    admin_dashboard: boolean;
    cloudflare_proxy: boolean;
    group_id: number;
    group: string;
    is_ssl: boolean;
    dns_provider: string;
    error_solution: string;
    status: string;
}

export enum KeitaroApiDomainState {
    ACTIVE = 'active',
    DELETED = 'deleted',
}

// Library: https://github.com/Lissy93/who-dat
// Open Api specification: https://who-dat.as93.net/docs.html
export interface WhoisApiDomainInfo {
    domain: {
        id: string;
        domain: string;
        name: string;
        extension: string;
        whois_server: string;
        status: [string];
        name_servers: [string];
        created_date: Date;
        updated_date: Date;
        expiration_date: Date;
    };
    registrar: WhoisApiContactInfo;
    registrant: WhoisApiContactInfo;
    administrative: WhoisApiContactInfo;
    technical: WhoisApiContactInfo;
    billing: WhoisApiContactInfo;
}

export interface WhoisApiContactInfo {
    name: string;
    organization: string;
    street: string;
    city: string;
    province: string;
    postal_code: string;
    country: string;
    phone: string;
    phone_ext: string;
    fax: string;
    fax_ext: string;
    email: string;
}

export enum DomainsWatcherExpireRange {
    THIRTY_DAYS = 'thirtyDays',
    FOURTEEN_DAYS = 'fourteenDays',
    THREE_DAYS = 'threeDays',
    TOMORROW = 'tomorrow',
    TODAY = 'today',
    EXPIRED = 'expired',
    NO_INFO = 'no_info',
    HAS_INFO = 'has_info',
}

export enum DomainsWatcherSources {
    MANUAL = 'MANUAL',
    KEITARO = 'KEITARO',
    KEITARO_DELETED = 'KEITARO_DELETED',
}

export enum DomainsWatcherSortColumn {
    ID = 'id',
    DOMAIN = 'domain',
    EXPIRE_DATE = 'expire_date',
    LAST_CHECK = 'last_check',
    SOURCE = 'source',
    SUB_DOMAINS_COUNT = 'sub_domains_count',
    COMMENT = 'comment',
    DISABLED = 'disabled',
    CREATED_AT = 'created_at',
    UPDATED_AT = 'updated_at',
    DELETED_AT = 'deleted_at',
}

export type DomainsWatcherDomain = {
    id: number
    domain: string
    source: DomainsWatcherSources
    keitaro: KeitaroApiDomain
    whois: WhoisApiDomainInfo
    comment: string
    sub_domains_count: number
    expire_date: string
    last_check: string
    created_at: string
    updated_at: string
}

export type DomainsWatcherDomainFilters = {
    offset: number
    limit: PageLimit
    column: DomainsWatcherSortColumn
    sort: SortOrder
} & DomainsWatcherSources

export type DomainsWatcherData = {
    count: number,
    filters: DomainsWatcherDomainFilters,
    domains: DomainsWatcherDomain[],
}

export type DomainsWatcherDomainResponse = {
    data: DomainsWatcherData
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}

export type WhoisApiDomainResponse = {
    data: WhoisApiDomainInfo
    status: number
    statusText: string
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
    config: InternalAxiosRequestConfig<any>
    request?: any
}
