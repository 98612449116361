import React, { useState } from 'react'
import styled from 'styled-components'

import { DomainInfoButtonProps } from '../types'

import { DomainInfoPopup } from './domain-info-popup'

export const DomainInfoButton = ({ domain }: DomainInfoButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <DomainInfoPopup
        onClose={() => setModalVisible(false)}
        isOpen={modalVisible}
        domain={domain}
      />

      <SDomainInfoButton onClick={() => setModalVisible(true)}>
        {domain.domain}
      </SDomainInfoButton>
    </>
  )
}

const SDomainInfoButton = styled.a`
    color: #007fa6;
    cursor: pointer;
    width: 500px;
`
