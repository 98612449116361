import React, { useState } from 'react'
import { t } from 'i18next'
import styled from 'styled-components'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { Tooltip } from '../../../../../shared/ui/tooltip'

import { ConfigureIntegrationModal } from './configure-integration-modal'

import type { ConfigureIntegrationButtonProps } from '../types'

export const ConfigureIntegrationButton = ({ integration, type }:ConfigureIntegrationButtonProps) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <ConfigureIntegrationModal
        onClose={() => setModalVisible(false)}
        initialValues={integration ?? { type }}
        isOpen={modalVisible}
      />

      <Tooltip title={t('settings:Configure integration')}>
        <SConfigureButton onClick={() => setModalVisible(true)}>
          { t('settings:Configure')}
          <SNavigateNextIcon/>
        </SConfigureButton>
      </Tooltip>
    </>
  )
}

const SNavigateNextIcon = styled(NavigateNextIcon)`
    color: #8c8b8b;
`

const SConfigureButton = styled.div`
    &:hover {
        color: initial;
        cursor: pointer;
        
        & ${SNavigateNextIcon} {
            color: initial;
        }
    }
`
