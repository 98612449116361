import React from 'react'
import { AppBar, Box, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'

import { useAppStore } from '../../entities/app'
import { UserSettings } from '../../features/topbar/user-settings'
import { TopBarLanguageSwitcher } from '../../features/topbar/language-switcher'

export function Topbar() {
  const { sidebarOpen, setSidebarOpen } = useAppStore()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: '#407698', height: '70px' }}>
        <Toolbar sx={{ height: '100%' }}>
          <CustomIconButton
            disableRipple
            aria-label="open drawer"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            edge="start"
            sx={{ ml: '24px', mr: '24px', color: 'white' }}
          >
            <MenuIcon/>
          </CustomIconButton>
          <Box sx={{ flexGrow: 1 }}/>
          {/*
          <Search/>
          <Box sx={ { display: { xs: 'none', md: 'flex' } } }>
            <QuickAdd/>
            <Reminder/>
            <Events/>
            <Notifications/>
          </Box>
          */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <TopBarLanguageSwitcher/>
          </Box>
          <UserSettings/>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: '35px',
  },
}))
