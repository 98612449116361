import React, { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'
import styled from 'styled-components'
import CachedIcon from '@mui/icons-material/Cached'
import { AxiosError } from 'axios'

import { Tooltip } from '../../../shared/ui/tooltip'
import { syncDomain, useDomainsWatcherStore } from '../../../entities/domains-watcher'
import { Button } from '../../../shared/ui/button'

import type { SyncDomainButtonProps } from './types'

export const SyncDomainButton: FC<SyncDomainButtonProps> = (props) => {
  const [search] = useSearchParams()
  const [updating, setUpdating] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { listDomains } = useDomainsWatcherStore()

  const handleClick = async () => {
    try {
      setUpdating(true)
      await syncDomain(props.initialValues.id)

      enqueueSnackbar(
        t('domains-watcher:{{name}} was synced successfully!', { name: props.initialValues.domain }),
        { variant: 'success' },
      )

      await listDomains(search)
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        enqueueSnackbar(
          `${t('domains-watcher:{{name}} can not be synced!', { name: props.initialValues.domain })} 
      ${t(`domains-watcher:${error.response?.data?.message ?? error.message}`)}`,
          { variant: 'error' },
        )
      } else {
        // Handle non-Axios errors (optional)
        enqueueSnackbar(
          `${t('domains-watcher:{{name}} can not be synced!', { name: props.initialValues.domain })}`,
          { variant: 'error' },
        )
      }
    } finally {
      setUpdating(false)
    }
  }

  return (
    <>
      <Tooltip title={t('domains-watcher:Sync')}>
        <SButton
          onClick={handleClick}
          $updating={updating}
          variant={'contained'}
          size={'small'}
          disabled={updating ?? false}
          type={'button'}
          startIcon={<CachedIcon/>}
        />
      </Tooltip>
    </>
  )
}

const SButton = styled(Button)<{ $updating?: boolean }>`
    &.MuiButtonBase-root {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFEF9D;
        box-shadow: 0 1px 1px 0 #aab7c1;
        color: #C89D08;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.1px;
        padding: 0;
        height: 26px;
        width: 26px;

        & .MuiButton-icon {
            margin: 0;
        }

        &:hover {
            background: #FFEF9D;
        }
    }

    & .MuiSvgIcon-root {
        animation: ${({ $updating }) => $updating
    ? 'rotate 1s linear infinite'
    : 'none'};
        height: 16px;
        width: 16px;
    }
`
