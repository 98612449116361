import styled from 'styled-components'

import { Pagination } from '../../../../shared/ui/pagination'
import { useWatchCatCatalogStore } from '../../../../entities/watch-cat/catalog'
import { PageLimit } from '../../../../shared/configs/enums/pagination'

export function PaginationBlock() {
  const { count } = useWatchCatCatalogStore()

  return (
    <SPaginationBlock>
      <Pagination count={count ?? 0} limits={Object.values(PageLimit).map(s => Number(s))}/>
    </SPaginationBlock>
  )
}

const SPaginationBlock = styled.div`
    display: flex;
    gap: 20px;
`
