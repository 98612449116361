/* eslint-disable max-len */
import { Icon } from '../../icon'

export function KeitaroLogo() {
  return (
    <Icon>
      <svg
        width="100" height="100"
        viewBox="0 0 100 100" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          width="100" height="100"
          fill="white"/>
        <path
          d="M22.1408 56.6645L17.4056 50.7323V56.6645H13.9756V43.2307H17.4056V49.1257L22.102 43.2307H26.1344L20.6768 49.8147L26.3352 56.6645H22.1408Z"
          fill="#62C862"/>
        <path
          d="M36.7288 52.1103H28.962C29.0138 52.7746 29.2405 53.2813 29.6324 53.6304C30.0275 53.9827 30.5101 54.1557 31.0867 54.1557C31.9417 54.1557 32.5377 53.8096 32.8713 53.1207H36.5215C36.3337 53.822 35.9967 54.4554 35.5078 55.0146C35.0187 55.5769 34.4065 56.0156 33.6713 56.3339C32.9361 56.6521 32.1134 56.8128 31.2033 56.8128C30.1053 56.8128 29.1304 56.5903 28.2721 56.1423C27.417 55.6974 26.7465 55.0579 26.2672 54.2298C25.7846 53.4018 25.5449 52.4317 25.5449 51.3194C25.5449 50.2102 25.7813 49.2401 26.2575 48.412C26.7304 47.584 27.3976 46.9445 28.2526 46.4965C29.1077 46.0516 30.0923 45.826 31.2033 45.826C32.285 45.826 33.2503 46.0423 34.0924 46.4779C34.9345 46.9105 35.5919 47.5315 36.068 48.3348C36.5441 49.1381 36.7807 50.0774 36.7807 51.1495C36.7872 51.4584 36.7677 51.7797 36.7288 52.1103ZM33.2762 50.2936C33.2762 49.7313 33.0753 49.2864 32.6737 48.9527C32.2721 48.6221 31.7701 48.4553 31.1676 48.4553C30.5943 48.4553 30.1085 48.616 29.7134 48.9342C29.3182 49.2524 29.0753 49.7066 28.9814 50.2936H33.2762Z"
          fill="#62C862"/>
        <path d="M41.8391 45.9866V56.6644H38.4092V45.9866H41.8391Z" fill="#62C862"/>
        <path
          d="M57.0491 45.8322C56.126 45.8322 55.2904 46.0546 54.5422 46.5026C53.7941 46.9506 53.2013 47.5871 52.7673 48.4182C52.3333 49.2462 52.1162 50.2102 52.1162 51.307C52.1162 52.4038 52.3333 53.3709 52.7673 54.2051C53.2013 55.0393 53.7941 55.685 54.5422 56.1392C55.2904 56.5934 56.1195 56.8189 57.0297 56.8189C57.8199 56.8189 58.5099 56.6644 59.1057 56.3585C59.7018 56.0527 60.1649 55.651 60.4985 55.1536V56.6644H63.9284V45.9866H60.4985V47.4975C60.1779 47 59.718 46.5984 59.1252 46.2925C58.5293 45.9866 57.8393 45.8322 57.0491 45.8322ZM60.1909 51.3255C60.1909 52.1412 59.9543 52.7869 59.4784 53.2596C59.0055 53.7324 58.4257 53.9672 57.7423 53.9672C57.0588 53.9672 56.4822 53.7293 56.0063 53.2504C55.5302 52.7715 55.2936 52.1257 55.2936 51.307C55.2936 50.4913 55.5302 49.8487 56.0063 49.3821C56.479 48.9156 57.0588 48.6839 57.7423 48.6839C58.4224 48.6839 59.0022 48.9187 59.4784 49.3914C59.9543 49.8641 60.1909 50.5099 60.1909 51.3255Z"
          fill="#62C862"/>
        <path
          d="M51.025 55.03C50.8371 54.6686 50.6817 54.2854 50.5554 53.89H49.6647C49.289 53.89 49.0235 53.822 48.8615 53.6799C48.6994 53.5408 48.6217 53.3091 48.6217 52.9909V48.7056H50.5619H50.8533H50.8695V48.6561V47.9177V45.9898H48.6217V43.3852H45.1917V45.9898H43.8281V48.7056H45.1917V52.9538C45.1917 54.2669 45.5384 55.2154 46.2347 55.7963C46.931 56.3771 47.8995 56.6675 49.1432 56.6675H52.1974C51.7408 56.1917 51.3489 55.648 51.025 55.03Z"
          fill="#62C862"/>
        <path
          d="M41.5546 41.5252C41.1627 41.1761 40.6865 41 40.1262 41C39.5625 41 39.0864 41.173 38.6945 41.5191C38.3026 41.8651 38.1084 42.3409 38.1084 42.9496C38.1084 43.5551 38.3026 44.0371 38.6945 44.3924C39.0864 44.7477 39.5625 44.9269 40.1229 44.9269C40.6833 44.9269 41.1627 44.7508 41.5513 44.4017C41.9432 44.0526 42.1374 43.5737 42.1374 42.965C42.1407 42.3564 41.9464 41.8775 41.5546 41.5252Z"
          fill="#62C862"/>
        <path
          d="M83.1092 46.5027C82.2282 46.0577 81.2373 45.8322 80.1392 45.8322C79.0413 45.8322 78.0534 46.0547 77.1692 46.5027C76.2882 46.9507 75.5919 47.5902 75.0834 48.4275C74.5748 49.2617 74.3223 50.2288 74.3223 51.3256C74.3223 52.4348 74.5716 53.4049 75.0736 54.236C75.5757 55.064 76.2623 55.7036 77.14 56.1485C78.0145 56.5965 79.0024 56.819 80.1003 56.819C81.1951 56.819 82.1895 56.5965 83.08 56.1485C83.9675 55.7036 84.6705 55.061 85.1855 54.2268C85.7003 53.3925 85.9563 52.4255 85.9563 51.3256C85.9563 50.2288 85.7035 49.2617 85.1952 48.4275C84.6867 47.5902 83.9934 46.9507 83.1092 46.5027ZM81.7748 53.2968C81.315 53.754 80.7547 53.9858 80.1003 53.9858C79.4461 53.9858 78.8987 53.7571 78.4647 53.2968C78.0307 52.8364 77.8138 52.1814 77.8138 51.3256C77.8138 50.4574 78.0339 49.7962 78.4744 49.3451C78.9149 48.8909 79.4721 48.6654 80.1392 48.6654C80.7935 48.6654 81.3472 48.894 81.7942 49.3544C82.2412 49.8147 82.4648 50.4698 82.4648 51.3256C82.468 52.1814 82.2379 52.8395 81.7748 53.2968Z"
          fill="#62C862"/>
        <path
          d="M74.5197 46.1041C74.1797 45.9496 73.7748 45.8723 73.2371 45.8723C72.7189 45.8723 71.8314 46.0423 71.2291 46.3791C70.6266 46.7189 70.1245 47.1793 69.7229 47.7663V45.9867H66.293V56.6645H69.7229V51.7087C69.7229 50.8281 69.9368 50.2133 70.3643 49.8611C70.7918 49.5119 71.433 49.3358 72.2913 49.3358H72.6283C72.7999 48.6808 73.0591 48.066 73.4056 47.4975C73.7197 46.9784 74.0922 46.515 74.5197 46.1041Z"
          fill="#62C862"/>
      </svg>
    </Icon>
  )
}
